import React, { useEffect, useState } from "react";

import "../../components/header/Style.css";

import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router";

export default function () {
  const [bank, setBank] = useState('')
  const [bankAccount, setBankAccount] = useState('')
  const [bankAccountDigit, setBankAccountDigit] = useState('')
  const [bankBranch, setBankBranch] = useState('')
  const [name, setName] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    const login = localStorage.getItem('login')
    if (!login) {
      navigate('/')
    }
    setBank(localStorage.getItem('bank'))
    setBankAccount(localStorage.getItem('bankAccount'))
    setBankAccountDigit(localStorage.getItem('bankAccountDigit'))
    setBankBranch(localStorage.getItem('bankBranch'))
    setName(localStorage.getItem('name'))
  }, [])

  const handleOnClick = () => {
    localStorage.clear()
    navigate('/')
  }

  return (
    <div className="header">
      <div className="container">
        <div className="cabecalho">
          <div className="row">
            <div className="MdOutlineNotificationsNone col-xl-4">
            </div>
            <div className="d-flex justify-content-end">

              <div className="perfil col-xl-1" style={{ marginRight: '15px' }}>
                <div style={{
                  height: '45px',
                  width: '45px',
                  borderRadius: '100px',
                  backgroundColor: '#ccc',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'end'
                }}>
                  <h4>{name.split(' ')[0].split('')[0]}</h4>
                </div>
              </div>

              <div className="agencia col-xl-1">
                <p>BANCO</p>
                <p className="codigo">{bank}</p>
              </div>

              <div className="linha col-xl-1">
                <div />
              </div>

              <div className="agencia col-xl-1">
                <p>AGÊNCIA</p>
                <p className="codigo">{bankBranch}</p>
              </div>

              <div className="linha col-xl-1">
                <div />
              </div>

              <div className="conta col-xl-2">
                <p>CONTA</p>
                <p className="codigo">{bankAccount + '-' + bankAccountDigit}</p>
              </div>

              <div className="logout col-xl-1">
                <button className="btn btn-default" onClick={handleOnClick}>Logout</button>
              </div>

              <div className="FaChevronDown col-xl-1">
                <FaSignOutAlt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
