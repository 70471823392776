import React from "react";

import "../pagina-deposito/Style.css"

import MenuLateral from "../../components/menu-lateral/MenuLateral"
import Header from "../../components/header/Header"
import BoasVindas from "../../components/boas-vindas/BoasVindas"
import Deposito from "../../components/acesso-rapido/deposito/Deposito"

export default function () {
  return (
    <div>
      <Header/>
      <MenuLateral />

      <div className="deposito" />
      <BoasVindas />
      <Deposito/>
    </div>
  );
}
