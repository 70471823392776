import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "../../../acesso-rapido/boleto/novo-boleto/Style.css";

import api from "../../../../services/api";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
import IntlCurrencyInput from "react-intl-currency-input" 


export default function () {

  const [valor, setValor] = useState(0)
  const [vencimento, setVencimento] = useState('')
  const [email, setEmail] = useState('')
  const [multa, setMulta] = useState('')
  const [juros, setJuros] = useState('')
  const [VencimentoBoleto, setVencimentoBoleto] = useState('')
  const [DescontoBoleto, setDescontoBoleto] = useState('')

  const navigate = useNavigate();

  async function nextForm() {
    if (valor == '' || vencimento == '' || email == '') {
      toast.error('Preencha todos os campos !')
      return;
    } else {
      navigate('/pagina-novo-boleto2', {state: {valor: valor, vencimento: vencimento.replaceAll('-', '/'), email: email}})
    }
  }

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();

    setValor(value); // value without mask (ex: 1234.56)
    console.log(maskedValue); // masked value (ex: R$1234,56)
};

  return (
    <div>
      <div className="tranferencia-boleto">
        <div className="container">
          <div className="boleto row">
            <div className="col-xl-6">
              <p>Boleto</p>
            </div>

            <div className="gera-boleto col-xl-2">
              <Link to={"/pagina-novo-boleto"}>
              <button style={{backgroundColor: "var(--secondary-color)"}}>Novo Boleto</button>
              </Link>
            </div>

            <div className="col-xl-2">
              <Link to={"/pagina-boleto"}>
                <button style={{ backgroundColor: "var(--secondary-color)" }}>Boletos</button>
              </Link>
            </div>
          </div>

          <div className="transferencia">
            <div className="row">
              <div className="scroll col-xl-6">
                <div>
                  <h1>Gerar Boleto</h1>
                </div>

                <div>
                  <h2>Qual o valor do boleto?</h2>
                </div>

                <div className="valor">
                  <p>Valor</p>
                  <IntlCurrencyInput
                    currency="BRL"
                    config={currencyConfig}
                    onChange={handleChange}
                    placeholder="Digite o valor a ser transferido"
                    value={valor}
                  />
                </div>

                <div className="vencimento">
                  <h2>Qual a data de vencimento?</h2>
                  <div >
                    <p>Vencimento</p>
                    <input
                      className="input-date"
                      type="date"
                      placeholder="Selecione uma data"
                      value={vencimento}
                      onChange={e => { setVencimento(e.target.value) }}
                    />
                  </div>

                </div>

                <div>
                  <h2>Precisamos do seu E-mail</h2>
                </div>

                <div className="valor">
                  <p>Email</p>
                  <input
                    type="text"
                    placeholder="Digite seu E-mail"
                    icons="FiCalendar "
                    value={email}
                    onChange={e => { setEmail(e.target.value) }}
                  />
                </div>

                <div className="botao-confirmar">
                  <button onClick={e => nextForm()}>Proximo</button>
                </div>


              </div>

              <div className="resumo-boleto col-xl-6">
                <div className="visualizar-lancamento">
                </div>
              </div>


            </div>
          </div>


        </div>

      </div>
      <ToastContainer />
    </div>
  );
}
