import React from "react";

import "../../pix/pix-row/Style.css"
import { BsFillPersonFill } from "react-icons/bs";
import { GrAdd } from "react-icons/gr";
import { BsTrashFill } from "react-icons/bs";
import { pixTypes } from '../../../../services/enums'
import { deleteKey, deleteKeyFiducia } from "../../../../services/pix.service";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ReactModal from "react-modal";
import { MdOutlineClose } from "react-icons/md";

export default function(props){
    const [transationalPassword, setTransationalPassword] = useState("")
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isFiduciaAccount, setIsFiduciaAccount] = useState(false)
    const navigate = useNavigate()
    let type = props.data.type
    let displayKeyType = pixTypes.filter((item)=>{
        return item.key == type
    })

    const confirm = () =>{
        let bank = localStorage.getItem('bank')
        if( bank != "450"){
            setIsFiduciaAccount(true)
        }else{
            setIsOpenModal(false)
            deleteKey(props.data.id, navigate)
        }
        setTimeout(() => {
            console.log('teste')
            window.location.reload(false);
        }, 4000);
    }

    const deletepixKeyFiducia = () => {
        if (!transationalPassword && bank !== "450") {
            toast.error("Digite a senha");
            return;
        }
        const token = localStorage.getItem('fiduciaToken')

        setIsOpenModal(false)
        deleteKeyFiducia(props.data.id, navigate, transationalPassword, token)
    }
    return(
        <div className="chaves mb-3">
            <div className="row">
                <div className="col-xl-2">
                    <BsFillPersonFill />
                </div>

                <div className="col-xl-8">
                    <h3>{displayKeyType[0].value}</h3>
                    <p>{props.data.key}</p>
                </div>
                <div className="col-xl-2 d-flex align-items-center justify-content-center">
                    <button className="deleteButton" onClick={()=>setIsOpenModal(true)}>
                        <BsTrashFill size={20}/>
                    </button>
                </div>
            </div>
            <ReactModal isOpen={isOpenModal} className={'password-modal'} overlayClassName={'overlay-password-modal'}>
                {!isFiduciaAccount ?
                <>
                    <label>Deseja realmente deletar essa chave?</label>
                    <div>
                        <button onClick={()=>confirm()}>Sim</button>
                        <button onClick={()=>setIsOpenModal(false)}>Não</button>
                    </div>
                </>
                :
                <>
                    <button className="modal-close-button" onClick={()=>setIsOpenModal(false)}><MdOutlineClose color="white" size={24}/></button>
                    <label>Digite sua senha</label>
                    <input type={'password'} value={transationalPassword} onChange={(e)=>setTransationalPassword(e.target.value)}/>
                    <button className="modal-confirm-button" onClick={() => deletepixKeyFiducia()}>Confirmar</button>
                </>
                }
            </ReactModal>
        </div>
    )
}