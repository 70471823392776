import React from "react";

import "../copia-dados-deposito/Style.css"

import MenuLateral from "../../../components/menu-lateral/MenuLateral";
import Header from "../../../components/header/Header";
import BoasVindas from "../../../components/boas-vindas/BoasVindas";
import CopiarDados from "../../../components/acesso-rapido/deposito/copiar-dados/index"

export default function(){
    return(
        <div>
            <MenuLateral/>
            <Header/>
            <div>
                <BoasVindas/>
                <CopiarDados/>
            </div>
        </div>
    )
}