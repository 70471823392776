import { toast } from "react-toastify";
import api from "./api";

export const getBalanceFromFitBank = async (balanceId, fiduciaToken) =>{
    let taxNumber = localStorage.getItem('taxNumber')
    let response = await api.patch(`/balance/update/${taxNumber}/${balanceId}`, {fiduciaToken})

    if(response.data.data.Success == "true"){
        return response.data.data.Balance
    }else{
        toast.error(response.data.message)
    }
}