import React from "react";

import "../novo-boleto-2/Style.css";

import Header from "../../../components/header/Header";
import MenuLateral from "../../../components/menu-lateral/MenuLateral";
import BoasVindas from "../../../components/boas-vindas/BoasVindas";
import NovoBoleto from "../../../components/acesso-rapido/boleto/novo-boleto-2/index";

export default function () {
  return (
    <div>
      <Header/>
      <MenuLateral />

      <div className="novo-boleto-2" />
      <BoasVindas />
      <NovoBoleto />   
    </div>
  );
}
