import React from "react";

import MenuLateral from "../../../../components/menu-lateral/MenuLateral";
import Header from "../../../../components/header/Header";
import BoasVindas from "../../../../components/boas-vindas/BoasVindas";
import CodigoSenha from "../../../../components/acesso-rapido/cartoes/cartao-senha/CartaoSenha"


export default function(){
    return(
        <div>
            <MenuLateral/>
            <Header/>
            <div>
                <BoasVindas/>
                <CodigoSenha/>
            </div>
        </div>
    )
}