import React, { useState, useEffect } from "react";

import "../../pages/login/Style.css";

import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import getBrowserFingerprint from "get-browser-fingerprint";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import { getImage } from "../../services/getAssets";
import {
  updateEntrysFromFiducia,
  updateEntrysFromFitBank,
} from "../../services/entrys.service";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import {
  firstAccessFiducia,
  isPasswordStrong,
  login,
} from "../../services/login.service";

export default function () {
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [newTransationalPassword, setNewTransationalPassword] = useState("");
  const [confirmnewTransationalPassword, setConfirmNewTransationalPassword] =
    useState("");
  const [loader, setLoader] = useState(false);
  const [firstAccess, setFirstAccess] = useState(false);
  const [firstAccessToken, setFirstAccessToken] = useState("");
  const [isFirstAccessConfirmed, setIsFirstAccessConfirmed] = useState(false);
  const navigate = useNavigate();
  const fingerprint = getBrowserFingerprint();

  const [device, setDevice] = useState(false);

  const override = css`
    display: block;
    width: 30px !important;
    height: 30px !important;
    left: 10px !important;
    top: 8px !important;
    position: absolute;
  `;

  async function requestLogin() {
    if (cpf == "" || password == "") {
      toast.error("Preencha os campos para fazer o login!");
      setPassword("");
      return;
    }

    setLoader(true);
    const loginResponse = await login(cpf,password,fingerprint)
    console.log("loginResponse ", loginResponse);
    if (loginResponse.ok) {
      const data = loginResponse.data;
      console.log("loginResponse.data ", loginResponse.data);

      try {
        if (data.account[0].bank !== "450") {
          await updateEntrysFromFiducia(
            loginResponse.fiduciaToken,
            data.account[0].id
          );
        } else {
          await updateEntrysFromFitBank(data.taxNumber, data.account[0].id);
        }
      } catch (err) {
        setLoader(false);
        navigate("/");
        toast.error("Ocorreu um erro ao atualizar o extrato, tente novamente");
      }

      setLoader(false);
      navigate("/pagina-principal");
    } else if (
      loginResponse.loginResponse.data.message == "Primeiro login, Altere sua senha"
    ) {
      setFirstAccess(true);
      setLoader(false);
      setFirstAccessToken(loginResponse.loginResponse.data.token);
    } else {
      setLoader(false);
    }
  }

  async function sendNewPassword() {
    console.log("newPassword", newPassword);
    console.log("confirmNewPassword", confirmNewPassword);
    console.log("newTransationalPassword", newTransationalPassword);
    console.log(
      "confirmnewTransationalPassword",
      confirmnewTransationalPassword
    );

    if (!isPasswordStrong(newPassword)) {
      toast.error(
        "Senha muito fraca, a senha deve conter pelo menos 8 dígitos, incluindo números, símbolos, letras minúsculas e maiúsculas"
      );
      return "";
    }

    if (newPassword !== confirmNewPassword) {
      toast.error("As senhas de acesso são diferentes");
      return "";
    }

    if (newTransationalPassword !== confirmnewTransationalPassword) {
      toast.error("As senhas de transação são diferentes");
      return "";
    }
    setLoader(true)
    const passwordResponse = await firstAccessFiducia(
      newPassword,
      newTransationalPassword,
      cpf,
      firstAccessToken
    );

    if(passwordResponse.ok){
      setIsFirstAccessConfirmed(true)
      setLoader(false)
    } else {
      toast.error('Ocorreu um erro! Tente novamente')
      setLoader(false)
    }
  }

  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login) {
      navigate("/pagina-principal");
    }
  }, []);
  return (
    <div className="pagina-login">
      <div className="logo row">
        <div className="col-xl-7">
          <img src={getImage("logo.png")} alt="" />
        </div>

        <div className="cadastro col-xl-5">
          <div className="acesse-sua-conta">
            <h1>Acesse sua conta agora mesmo!</h1>
          </div>

          {!firstAccess ? (
            <div className="area-login login">
              <div>
                <p>CPF/CNPJ</p>
                <input
                  type="text"
                  placeholder="Digite seu CPF ou CNPJ"
                  value={cpf}
                  onChange={(e) => {
                    setCpf(e.target.value);
                  }}
                />
              </div>

              <div className="senha">
                <p>Senha</p>
                <input
                  type="password"
                  placeholder="Digite sua Senha"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>

              <div className="logar">
                <button onClick={(e) => requestLogin()}>
                  <ClipLoader loading={loader} color="white" css={override} />
                  <span>Avançar</span>
                </button>
              </div>
            </div>
          ) : !isFirstAccessConfirmed ? (
            <div className="area-login first-access">
              <h1>Este é o seu primeiro acesso</h1>
              <h2>Defina suas senhas</h2>

              <div className="new-password-area">
                <div className="col-xl-6">
                  <label>Senha de Acesso *</label>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-6">
                  <label>Confirme Senha de Acesso *</label>
                  <input
                    type="password"
                    value={confirmNewPassword}
                    onChange={(e) => {
                      setConfirmNewPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
              <h6>
                *minimo 8 caracteres, contendo número, letra maiúscula e letra
                minúscula
              </h6>
              <div className="transational-password-area">
                <div className="col-xl-6">
                  <label>Senha de Transações</label>
                  <input
                    type="password"
                    value={newTransationalPassword}
                    onChange={(e) => {
                      setNewTransationalPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-6">
                  <label>Confirme Senha de Transações</label>
                  <input
                    type="password"
                    value={confirmnewTransationalPassword}
                    onChange={(e) => {
                      setConfirmNewTransationalPassword(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="button-area">
                <button
                  onClick={() => {
                    sendNewPassword();
                  }}
                >
                  Confirmar
                </button>
              </div>
            </div>
          ) : (
            <div className="area-login first-access-confirm">
              <h1>Senhas definidas com sucesso!</h1>
              <h2>Você já pode acessar sua conta!</h2>

              <div>
                <button
                  onClick={() => {
                    setFirstAccess(false);
                    setIsFirstAccessConfirmed(false);
                  }}
                >
                  Voltar
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      :
      <ToastContainer />
    </div>
  );
}
