import React from "react";

import { Routes, Route } from "react-router-dom";

import Login from "../src/pages/login/Login";
import PaginaPrincipal from "./pages/pagina-principal/PaginaPrincipal";
import Pix from "../src/pages/Pagina-pix/PaginaPix";
import Boleto from "../src/pages/pagina-boleto/PaginaBoleto";
import NovoBoleto from "./pages/pagina-boleto/novo-boleto/index";
import PagarBoleto from "./pages/pagina-boleto/pagar-boleto/index"
import ResumoBoleto from "./pages/pagina-boleto/resumo-boleto/index";
import NovoBoleto2 from "./pages/pagina-boleto/novo-boleto-2";
import Deposito from "../src/pages/pagina-deposito/index"
import GerarCodigo from "../src/pages/Pagina-pix/gerar-codigo-pix/GerarCodigo"
import Pagar from "../src/pages/pagina-pagar/PaginaPagar"
import CopiarDados from "../src/pages/pagina-deposito/copia-dados-deposito/CopiarDados"
import Senha from "../src/pages/pagina-cartões/senha-cartao/codigo-senha/index"
import VerSenha from "../src/pages/pagina-cartões/senha-cartao/ver-senha/index";
import CartaoDebito from "../src/pages/pagina-cartões/cartao-debito/opcoes-cartao-debito/index"
import CartaoCredito from "./pages/pagina-cartões/cartao-credito/opçoes-cartao-credito/cartao-credito";
import SenhaPagamentoFatura from "../src/pages/pagina-cartões/cartao-credito/senha-fatura-credito/SenhaFaturaCredito"
import PagamentoCartaoCredito from "../src/pages/pagina-cartões/cartao-credito/pagamento-credito/PagamentoCartaoCredito"
import FaturaCartaoCredito from "../src/pages/pagina-cartões/cartao-credito/fatura-cartao-credito/index"
import PaginaCartoes from "./pages/pagina-cartões/PaginaCartoes";
import SemCartaoCredito from "./pages/pagina-cartões/sem-cartao-credito";
import BloqueioCartao from "../src/pages/pagina-cartões/bloquear-cartao/index"
import LimteCartaoCredito from "./pages/pagina-cartões/cartao-credito/ajuste-limite-credito";
import Transferir from "../src/pages/pagina-transferir/PaginaTransferir";
import MinhaChaves from "./pages/Pagina-pix/minhas-chaves/MinhaChaves";
import CadastrarChavePix from "../src/pages/Pagina-pix/nova-chave-pix/CadastrarChavePix"
import ConfirmarChavePix from "./pages/Pagina-pix/confirmar-chave-pix/ConfirmarChavePix";


export default function () {
  return (
    <Routes>
     <Route path="/" element={<Login />} />
      <Route path="/pagina-principal" element={<PaginaPrincipal />} />

      <Route path="/pagina-pix" element={<Pix />} />
      <Route path="/gerar-codigo" element={<GerarCodigo />} />
      <Route path="/chave-pix" element={<MinhaChaves />} />
      <Route path="/nova-chave-pix" element={<CadastrarChavePix />} />
      <Route path="/confirmar-chave-pix" element={<ConfirmarChavePix />} />
      

      <Route path="/pagina-deposito" element={<Deposito />} />
      <Route path="/copiar-dados" element={<CopiarDados />} />

      <Route path="/pagina-tranferencia" element={<Transferir/>} />

      <Route path="/pagina-pagar" element={<Pagar />} />

      <Route path="/pagina-boleto" element={<Boleto />} />
      <Route path="/pagina-resumo-boleto" element={<ResumoBoleto />} />
      <Route path="/pagina-pagar-boleto" element={<PagarBoleto/>} />
      <Route path="/pagina-novo-boleto" element={<NovoBoleto />} />
      <Route path="/pagina-novo-boleto2" element={<NovoBoleto2 />} />

      <Route path="/codigo-senha" element={<Senha/>} />
      <Route path="/ver-senha-cartao" element={<VerSenha/>} />

      <Route path="/opcoes-cartao-credito" element={<CartaoCredito/>} />
      <Route path="/fatura-cartao-credito" element={<FaturaCartaoCredito/>} />
      <Route path="/ajuste-limite-credito" element={<LimteCartaoCredito/>} />
      <Route path="/pagamento-efetuado" element={<PagamentoCartaoCredito/>} />
      <Route path="/senha-pagamento-fatura" element={<SenhaPagamentoFatura/>} />

      <Route path="/opcoes-cartao-debito" element={<CartaoDebito/>} />
      <Route path="/bloquear-cartao" element={<BloqueioCartao/>} />

      <Route path="/pagina-cartoes" element={<PaginaCartoes/>} />
      <Route path="/sem-cartao-credito" element={<SemCartaoCredito/>} />
    </Routes>
  );
}
