import { toast } from "react-toastify";
import api from "./api";

export const sendTedFitBank = async ( data ) => {
    let response = await api.post("/transfer/fitbank/store", data)
    .catch((err) => {
      toast.error(err.response.data.message);
      return;
    });

  return response.data
}

export const sendTedFiducia = async ( data ) => {
    let response = await api.post("/transfer/fiducia/store", data)
    .catch((err) => {
      toast.error(err.response.data.message);
      return;
    });

    return response.data
}