import React from "react";

import "../pagina-pagar/Style.css"

import MenuLateral from "../../components/menu-lateral/MenuLateral"
import Header from "../../components/header/Header"
import BoasVindas from "../../components/boas-vindas/BoasVindas"
import Pagar from "../../components/acesso-rapido/pagar/Pagar"

export default function(){
    return(
        <>
            <MenuLateral/>
            <Header/>
            <BoasVindas/>
            <Pagar/>
        </>
    )
}