import React from "react";

import "../../components/acesso-rapido/Style.css";

import { Link } from "react-router-dom";

/* icones */

import { FaWallet } from "react-icons/fa";
import { IoIosWallet } from "react-icons/io";
import { BsCreditCard2Back } from "react-icons/bs";
import { MdOutlineQrCode2 } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import { AiOutlineArrowDown } from "react-icons/ai";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaPix } from "react-icons/fa6";
import { BsUpc } from "react-icons/bs";
import { BsUpcScan } from "react-icons/bs";
import { IoMdListBox } from 'react-icons/io'

export default function () {
  return (
    <div className="componente-acesso">
      <div className="acesso-rapido">
        <h1>ACESSO RÁPIDO</h1>
      </div>

      <div className="scroll">

        <Link to={"/pagina-principal"}>
          <div className="tipo-acesso" >
            <div className="row">
              <div className="FaWallet col-xl-6">
                <IoMdListBox/>
              </div>

              <div className="col-xl-6">
                <p>Extrato</p>
              </div>
            </div>
          </div>
        </Link>

        <Link to={"/pagina-pix"}>
          <div className="tipo-acesso" >
            <div className="row">
              <div className="FaWallet col-xl-6">
                <FaPix/>
              </div>

              <div className="col-xl-6">
                <p>Pix</p>
              </div>
            </div>
          </div>
        </Link>

        {/* <Link to={"/pagina-pagar"}>
          <div className="tipo-acesso" >
            <div className="row">
              <div className="FaWallet col-xl-6">
                < IoIosWallet />
              </div>

              <div className="col-xl-6">
                <p>Pagar</p>
              </div>
            </div>
          </div>
        </Link> */}

        {/* 
        <Link to={"/pagina-cartoes"}>
          <div className="tipo-acesso" >
            <div className="row">
              <div className="FaWallet col-xl-6">
                <BsCreditCard2Back />
              </div>

              <div className="col-xl-6">
                <p>Cartões</p>
              </div>
            </div>
          </div>
        </Link> */}


        {/* <Link to={"/pagina-deposito"}>
          <div className="tipo-acesso" >
            <div className="row">
              <div className="FaWallet col-xl-6">
                <MdOutlineQrCode2 />
              </div>

              <div className="col-xl-6">
                <p>Cobrar</p>
              </div>
            </div>
          </div>
        </Link> */}

        <Link to={"/pagina-tranferencia"}>
          <div className="tipo-acesso" >
            <div className="row">
              <div className="FaWallet col-xl-6">
                <BiTransfer />
              </div>

              <div className="col-xl-6">
                <p>TED</p>
              </div>
            </div>
          </div>
        </Link> 

        {/* <Link to={"/pagina-deposito"}>
          <div className="tipo-acesso" >
            <div className="row">
              <div className="FaWallet col-xl-6">
                <AiOutlineArrowDown />
              </div>

              <div className="col-xl-6">
                <p>Depositar</p>
              </div>
            </div>
          </div>
        </Link> */}

        {/* <Link to={""}>
          <div className="tipo-acesso" >
            <div className="row">
              <div className="FaWallet col-xl-6">
                <FaMoneyBillWave />
              </div>

              <div className="col-xl-6">
                <p>Emprestimos</p>
              </div>
            </div>
          </div>
        </Link> */}

        <Link to={"/pagina-pagar-boleto"}>
          <div className="tipo-acesso" >
            <div className="row">
              <div className="FaWallet col-xl-6">
                <BsUpc />
              </div>

              <div className="col-xl-6">
                <p>Boleto</p>
              </div>
            </div>
          </div>
        </Link> 
        {/*
        <Link to={"/pagina-pagar-boleto"}>
          <div className="tipo-acesso" >
            <div className="row">
              <div className="FaWallet col-xl-6">
                <BsUpcScan />
              </div>

              <div className="col-xl-6">
                <p>Pagar</p>
              </div>
            </div>
          </div>
        </Link> */}

      </div>
    </div>
  );
}
