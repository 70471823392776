import React, { useState } from "react";
import api from '../../../../../services/api';

import { Link, useNavigate } from "react-router-dom";

import './Styles.css';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { FiPlusCircle } from "react-icons/fi";

export default function () {

  const [limite, setLimite] = useState('')

  const navigate = useNavigate();

  async function AdjustLimit() {
    if (limite == '') {
      toast.error('Preencha todos os campos!')
      return;
    }

    const obj = {
      username: localStorage.getItem('taxNumber'),
      password: localStorage.getItem('identifier'),
      mac: localStorage.getItem('deviceId'),
      login: localStorage.getItem('login')
    }

    await api.post('/login/token', obj)
      .then(result => {
        localStorage.setItem('token', result.data.token)
      })

    await api.post('', {

    })
      .then(result => {
        console.log(result.data.data)
        const data = result.data.data
      })
      .catch(err => {
        console.log(err)
        toast.error('Erro por favor tente novamente')
      })

  }

  return (
    <div>
      <div className="forms">
        <div className="container">
          <p>Ajuste de limite</p>
          <div className="limit">
            <h1>Ajuste o limite de seu cartão de crédito!</h1>
            <div className="limit-container">

              <div className="value">
                <p>Seu limite atual é de: R$1000.00</p>
              </div>
              <div>
                <p>Digite o valor desejado para analise</p>
                <input
                  type="text"
                  placeholder="R$"
                  value={limite}
                  onChange={e => { setLimite(e.target.value) }}
                />

              </div>
              <div className="botao-ajustar">
                <button onClick={e => AdjustLimit()}>Ajustar limite</button>
              </div>
              <div className="icon">
                <FiPlusCircle size={40} />
                <p>Pedir aumento</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
