import api from "./api";
import { toast } from "react-toastify";

export function isPasswordStrong(password) {
  if (password.length < 8) {
    return false;
  }

  if (!/[A-Z]/.test(password)) {
    return false;
  }

  if (!/[a-z]/.test(password)) {
    return false;
  }

  if (!/[0-9]/.test(password)) {
    return false;
  }

  if (!/[!@#$%^&*()\-_=+{};:,<.>/?]/.test(password)) {
    return false;
  }

  return true;
}

export async function firstAccessFiducia(
  password,
  transational_password,
  cpf_cnpj,
  token
) {
  const payload = {
    password,
    transational_password,
    cpf_cnpj,
    token,
  };
  try {
    const passwordResponse = await api.post("/login/firstaccess", payload);

    const data = passwordResponse.data;
    console.log("data ", data);
    return {ok: true, data}
  } catch (err) {
    console.log("err ", err);
    return {ok: false, erro: err}
  }
}

export async function login(cpf, password, fingerprint) {
  try {
    const loginResponse = await api.post("/login/auth", {
      username: cpf,
      password: password,
      mac: fingerprint,
    });

    if (loginResponse.data.ok) {
      const data = loginResponse.data.data;
      localStorage.setItem("name", data.name);
      localStorage.setItem("taxNumber", data.taxNumber);
      localStorage.setItem("identifier", password);
      localStorage.setItem("deviceId", fingerprint);
      localStorage.setItem("personId", data.account[0].personId);
      localStorage.setItem("accountId", data.account[0].id);
      localStorage.setItem("bank", data.account[0].bank);
      localStorage.setItem("bankAccount", data.account[0].bankAccount);
      localStorage.setItem(
        "bankAccountDigit",
        data.account[0].bankAccountDigit
      );
      localStorage.setItem("bankBranch", data.account[0].bankBranch);
      localStorage.setItem("login", loginResponse.data.login);
      localStorage.setItem("fiduciaToken", loginResponse.data.fiduciaToken);

      return { ok: true, data: data, fiduciaToken: loginResponse.data.fiduciaToken };
    } else if (
      loginResponse.data.message == "Primeiro login, Altere sua senha"
    ) {
      return { ok: false, loginResponse };
    } else {
      toast.error("Ocorreu um erro! Tente novamente");
      return { ok: false, loginResponse };
    }
  } catch (err) {
    console.log("err ", err);
    toast.error("Usuário ou Senha incorretos!");
    return { ok: false, erro: "Usuário ou Senha incorretos!"}
  }
}
