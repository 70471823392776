import React from "react";

import "../opcoes-cartao-debito/Style.css"

import { Link } from "react-router-dom"

export default function () {
    return (
        <div className="opcoes-debito">
            <div className="container">

                <div className="titulo">
                    <p>Cartão de Debito</p>
                </div>

                <div className="selecione-opcao">
                    <div>
                        <h1>Cartão de debito</h1>
                        <p>Selecione a opção desejada</p>
                    </div>

                    <Link to={"/bloquear-cartao"}>
                        <div className="bloco">
                            <div>
                                <h2>Bloqueio temporário</h2>
                                <p>Realize o bloqueio temporário de seu cartão e desfasa quando quiser!</p>
                            </div>
                        </div>
                    </Link>

                    <Link to={"/codigo-senha"}>
                        <div className="bloco">
                            <h2>Ver senha do cartão</h2>
                            <p>Visualize a senha de seu cartão.</p>
                        </div>

                    </Link>

                    <Link to={"/bloquear-cartao"}>
                        <div className="bloco">
                            <div>
                                <h2>Cancelar cartão</h2>
                                <p>Escolha essa opção em caso de perda ou roubo.</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}