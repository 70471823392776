import React from "react";

import "./Style.css";

import MenuLateral from "../../../components/menu-lateral/MenuLateral";
import Header from "../../../components/header/Header";
import BoasVindas from "../../../components/boas-vindas/BoasVindas";
import PagarBoleto from "../../../components/acesso-rapido/boleto/pagar-boleto/index";

export default function () {
  return (
    <>
      <Header />
      <MenuLateral />
      <BoasVindas />
      <PagarBoleto />
    </>
  );
}
