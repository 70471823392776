import React, { useState, useEffect } from "react";
import api, { apiUrl } from '../../../../services/api';
import axios from 'axios';

import "../novo-boleto-2/Style.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
import BeatLoader from "react-spinners/BeatLoader";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { css } from "@emotion/react";

export default function () {

  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [celular, setCelular] = useState('');
  const [email, setEmail] = useState('');
  const [comments, setComments] = useState('')
  const [description, setDescription] = useState('')
  const [response, setResponse] = useState({})
  const [code, setCode] = useState('')

  const [receipt, setReceipt] = useState(false)
  const [loader, setLoader] = useState(false)

  const navigate = useNavigate();
  const location = useLocation();

  const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

  useEffect(() => {
    console.log(response.id)
    if (response.id) {
      setCode(JSON.parse(response.boleto).CodigoBarra.TypefulLine);
    }
  }, [response])

  async function generateTicket() {
    setLoader(true)

    if (nome == '' || cpf == '' || celular == '' || email == '') {
      toast.error('Preencha todos os campos!')
      setLoader(false)
      return;
    }

    const body = {
      accountId: localStorage.getItem('accountId'),
      supplierTaxNumber: localStorage.getItem('taxNumber'),

      customerName: nome,
      customerTaxNumber: cpf,
      customerMail: email,
      customerPhone: celular,

      mailToSend: location.state.email,
      phoneToSend: celular,

      comments: comments,
      description: description,

      dueDate: location.state.vencimento,
      totalValue: location.state.valor,
    }

    const obj = {
      username: localStorage.getItem('taxNumber'),
      password: localStorage.getItem('identifier'),
      mac: localStorage.getItem('deviceId'),
      login: localStorage.getItem('login')
    }

    await api.post('/login/token', obj)
      .then(async function (result) {
        localStorage.setItem('token', result.data.token)

        const api2 = await axios.create({
          headers: {
              'Authorization': result.data.token,
              'device': localStorage.getItem('deviceId')
          },
          baseURL: apiUrl
        })

        var call = await api2.post('/boleto/store', body)
          .catch(err => {
            setLoader(false)
            toast.error(err.response.data.message)
            return;
          })

        var res = call.data.data
        toast.success('Boleto gerado com sucesso! Clique no botão abaixo do resumo para fazer o download!')
        setResponse(res)
        console.log(res)

        setLoader(false)
        setReceipt(true)
      })
  }

  return (
    <div>
      <div className="novo-boleto-2">
        <div className="container">
          <div className="boleto row">
            <div className="col-xl-6">
              <p>Boleto</p>
            </div>

            <div className="gera-boleto col-xl-2">
              <Link to={"/pagina-novo-boleto"}>
                <button style={{ backgroundColor: "var(--secondary-color)" }}>Novo Boleto</button>
              </Link>
            </div>


            <div className="col-xl-2">
              <Link to={"/pagina-boleto"}>
                <button style={{ backgroundColor: "var(--secondary-color)" }}>Boletos</button>
              </Link>
            </div>

          </div>

          <div className="forms">
            <div className="row">
              <div className="col-xl-6">
                <div>
                  <h1>Gerar Boleto</h1>
                </div>

                <div>
                  <h2>Precisamos saber os dados de quem vai pagar</h2>
                </div>

                <div className="scroll">
                  <div className="dados">
                    <div>
                      <p>DADOS PESSOAIS</p>
                    </div>

                    <div>
                      <p>Nome completo</p>
                      <input
                        type="text"
                        placeholder="Nome completo"
                        value={nome}
                        onChange={e => { setNome(e.target.value) }}
                      />
                    </div>
                    <div>
                      <p>CPF</p>
                      <input
                        type="text"
                        placeholder="CPF"
                        value={cpf}
                        onChange={e => { setCpf(e.target.value) }}
                      />
                    </div>


                    <div>
                      <p>DADOS DE CONTATO</p>
                    </div>
                    <div>
                      <p>Celular</p>
                      <input
                        type="tel"
                        placeholder="(DDD) + NUMERO"
                        value={celular}
                        onChange={e => { setCelular(e.target.value) }}
                      />
                    </div>

                    <div>
                      <p>E-mail</p>
                      <input
                        type="email"
                        placeholder="E-mail"
                        value={email}
                        onChange={e => { setEmail(e.target.value) }}
                      />
                    </div>

                    <div>
                      <p>OBSERVAÇÕES DO BOLETO</p>
                    </div>

                    <div>
                      <p>Produto/serviços</p>
                      <input
                        type="text"
                        placeholder="Descrição do Produto"
                        value={description}
                        onChange={e => { setDescription(e.target.value) }}
                      />
                    </div>

                    <div>
                      <p>Descrição</p>
                      <input
                        className="descricao"
                        type="text"
                        placeholder="Descrição"
                        value={comments}
                        onChange={e => { setComments(e.target.value) }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6">
                <div className="resumo-transferencia">
                  <div>
                    <h1>Resumo do Boleto</h1>
                  </div>

                  <div style={receipt ? { 'display': 'block' } : { 'display': 'none' }}>
                    <div>
                      <h2>{nome}</h2>
                    </div>

                    <div>
                      <p>CPF/CNPJ</p>
                      <h4>{cpf}</h4>
                    </div>

                    <div>
                      <p>Data de vencimento</p>
                      <h4>{location.state.vencimento}</h4>
                    </div>
                    <div>
                      <p>Valor</p>
                      <h4>R$ {location.state.valor}</h4>
                    </div>

                    <div>
                      <p>CÓDIGO DO BOLETO</p>
                      <h4>{code}</h4>
                    </div>
                  </div>

                  <div style={loader ? { 'display': 'block', 'margin': 'auto', 'marginTop': '20px' } : { 'display': 'none' }}>
                    <BeatLoader color={"var(--secondary-color)"} loading={loader} css={override} size={20} />
                  </div>

                </div>
                <div className="botao-confirmar">
                  <button
                    style={loader || receipt ? { 'display': 'none' } : { 'display': 'block' }}
                    onClick={() => generateTicket()}>Gerar boleto
                  </button>

                  <button
                    style={loader ? { 'display': 'block' } : { 'display': 'none' }}
                    onClick={() => toast.info('Requisição em processamento!')} >
                    <BeatLoader color={"#fff"} loading={loader} css={override} size={10} />
                  </button>

                  <a href={`https://techtomarket.com.br/boleto/boleto_sicredi.php?id=${response.id}`} target="_blank">
                    <button
                      style={receipt ? { 'display': 'block' } : { 'display': 'none' }}
                    >
                      Download do boleto
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
