import React, { useState, useContext } from "react";

import "./Style.css"
import { pixTypes } from "../../../../services/enums";
import { pixContext } from "../../../../services/Context";
import { Link, useNavigate } from "react-router-dom";
import { CreateKey } from "../../../../services/pix.service";
import ReactModal from "react-modal";
import { MdOutlineClose } from "react-icons/md";

export default function () {
    const {keyValue, keyType} = useContext(pixContext);
    const [transationalPassword, setTransationalPassword] = useState("")
    const [isOpenModal, setIsOpenModal] = useState(false)
    
    const navigate = useNavigate()
    let displayKeyType = pixTypes.filter((item)=>{
        return item.key == keyType
    })

    const confirm = () =>{
        let bank = localStorage.getItem('bank')
        if( bank != "450"){
          setIsOpenModal(true)
        }else{
          createPixKey()
        }
    }

    const createPixKey = () => {
        let bank = localStorage.getItem('bank')
        if (!transationalPassword && bank !== "450") {
            toast.error("Digite a senha");
            return;
        }
        setIsOpenModal(false)
        CreateKey(keyType,keyValue,transationalPassword, navigate)
    }

    return (
        <div className="create-pix-key">
            <div className="container">
            <div className='titulo row'>
                    <div className='col-xl-8'>
                        <p>Minhas Chaves Pix</p>
                    </div>
                    <div className="col-xl-4 d-flex gap-2 justify-content-end">
                        <div className="">
                            <Link to={"/pagina-pix"}>
                                <button style={{ backgroundColor: "var(--secondary-color)" }}>Nova transferencia</button>
                            </Link>
                        </div>

                        <div className="">
                            <Link to={"/nova-chave-pix"}>
                                <button style={{ backgroundColor: "var(--secondary-color)" }}>Cadastrar Chave</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="confirmacao-chave-pix">
                    <div>
                        <h1>Confirma a criação da chave pix?</h1>
                    </div>
                    <div>
                        <p><strong>Tipo da Chave:</strong> {displayKeyType[0].value}</p>
                        <p><strong>Chave:</strong> {keyValue ? keyValue : "Chave será gerada aleatóriamente"}</p>
                    </div>

                    <div className="">
                            <button style={{ backgroundColor: "var(--secondary-color)" }} className="btn btn-primary col-md-2 me-2" onClick={()=>confirm()}>Confirmar</button>
                            <button className="btn btn-secondary col-md-2" onClick={()=>navigate('/nova-chave-pix')}>Cancelar</button>
                    </div>
                </div>
            </div>
            <ReactModal isOpen={isOpenModal} className={'password-modal'} overlayClassName={'overlay-password-modal'}>
                <button className="modal-close-button" onClick={()=>setIsOpenModal(false)}><MdOutlineClose color="white" size={24}/></button>
                <label>Digite sua senha</label>
                <input type={'password'} value={transationalPassword} onChange={(e)=>setTransationalPassword(e.target.value)}/>
                <button className="modal-confirm-button" onClick={() => createPixKey()}>Confirmar</button>
            </ReactModal>
        </div>
    )
}