import axios from "axios";

const getUrlApi = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000/api/v0";
  } else {
    // return `https://api.${process.env.REACT_APP_COMPANY_KEY}.x-pay.app/api/v0`
    return process.env.REACT_APP_URL_API;
  }
};
export const apiUrl = getUrlApi();
//export const apiUrl = 'https://api.fomento.x-pay.app/api/v0'

const api = axios.create({
  headers: {
    Authorization: localStorage.getItem("token"),
    device: localStorage.getItem("deviceId"),
  },
  baseURL: getUrlApi(), // backend
  //baseURL: 'http://localhost:3000/api/v0'//api
});

export default api;
