import React, { useState } from "react";

import { BrowserRouter } from "react-router-dom";
import Routes from "../src/Routes";
import { pixContext, themeContext } from "./services/Context";
import "bootstrap/dist/css/bootstrap.min.css";
import './styles/global.css';

function App() {
  const [keyValue, setKeyValue] = useState('')
  const [keyType, setKeyType] = useState(0)
  const [theme, setTheme] = useState(process.env.REACT_APP_THEME)

  return (
    <themeContext.Provider value={ {theme} }>
      <pixContext.Provider value={{keyValue, setKeyValue, keyType, setKeyType}}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </pixContext.Provider>
    </themeContext.Provider>
  );
}

export default App;
