import React from "react";

import "../Pagina-pix/Style.css";

import MenuLateral from "../../components/menu-lateral/MenuLateral";
import Pix from "../../components/acesso-rapido/pix/Pix";
import Header from "../../components/header/Header";
import BoasVindas from "../../components/boas-vindas/BoasVindas";

export default function () {
  return (
    <>
      <Header />
      <MenuLateral />
      <BoasVindas />
      <Pix />
    </>
  );
}
