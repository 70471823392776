import React from "react";

import MenuLateral from "../../components/menu-lateral/MenuLateral";
import Header from "../../components/header/Header";
import BoasVindas from "../../components/boas-vindas/BoasVindas";
import Transferencia from "../../components/acesso-rapido/transferir/Transferir";

export default function(){
    return(
        <>
            <MenuLateral/>
            <Header/>
            <BoasVindas/>
            <Transferencia/>
        </>
    )
}