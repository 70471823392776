import React from "react";

import Header from "../../../components/header/Header";
import MenuLateral from "../../../components/menu-lateral/MenuLateral";
import BoasVindas from "../../../components/boas-vindas/BoasVindas";
import CadastroChavePix from "../../../components/acesso-rapido/pix/cadastrar-chave-pix/CadastroChavePix"

export default function(){
    return(
        <>
            <Header/>
            <MenuLateral/>
            <BoasVindas/>
            <CadastroChavePix/>
        </>
    )
}