import React, { useState } from "react";
import "./Style.css";

import api, { apiUrl } from '../../../../services/api';
import axios from 'axios';

import { Link, useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { css } from "@emotion/react";
import 'react-toastify/dist/ReactToastify.min.css';

import BeatLoader from "react-spinners/BeatLoader";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { BsPaypal } from "react-icons/bs";
import moment from "moment";
import ReactModal from "react-modal";
import { MdOutlineClose } from "react-icons/md";


export default function () {

  const [CodigoBarra, setCodigoBarra] = useState('')
  const [transationalPassword, setTransationalPassword] = useState('')
  const [loader, setLoader] = useState(false)

  const [info, setInfo] = useState(false)
  const [response, setResponse] = useState(false)
  const [due, setDue] = useState(false)
  const [pay, setPay] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false);

  const navigate = useNavigate();

  const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

  const confirm = () => {
    let bank = localStorage.getItem("bank");
    if (bank != "450") {
      setIsOpenModal(true);
    } else {
      PayTicket();
    }
  };

  async function PayTicket() {
    setLoader(true)
    setPay(false)
    setIsOpenModal(false)

    let bank = localStorage.getItem("bank");
    
    if (!transationalPassword && bank !== "450") {
      toast.error("Digite a senha");
      return;
    }

    let dueList = due.split('/');

    const body = {
      taxNumber: localStorage.getItem('taxNumber'),
      barCode: response.DigitableLine,
      dueDate: dueList[2]+'/'+dueList[1]+'/'+dueList[0],
      value: response.Value
    }

    const obj = {
      username: localStorage.getItem('taxNumber'),
      password: localStorage.getItem('identifier'),
      mac: localStorage.getItem('deviceId'),
      login: localStorage.getItem('login'),
      token: localStorage.getItem('fiduciaToken')
    }    

    await api.post('/login/token', obj)
      .then(async function (result) {
        localStorage.setItem('token', result.data.token);

        const api2 = await axios.create({
            headers: {
                'Authorization': result.data.token,
                'device': localStorage.getItem('deviceId')
            },
            baseURL: apiUrl
        })

        const bank = localStorage.getItem('bank')
        if(bank === '450'){
          var pay = await api2.post('/payment/store', body)
          .then(response => {
            toast.success('Boleto pago com sucesso!')
          })
          .catch(err => {
              setLoader(false)
              setPay(true)
              toast.error(err.response.data.message)
              return;
          })
        } else {
          let payload = {
            ...response,
            taxNumber: body.taxNumber,
            transational_password: transationalPassword,
            token: localStorage.getItem('fiduciaToken')
          }

          var pay = await api2.post('/payment/store', payload)
          .then(response => {
            toast.success('Boleto pago com sucesso!')
            setLoader(false)
            setInfo(true)
            setPay(true)
          })
          .catch(err => {
              setLoader(false)
              setPay(true)
              toast.error(err.response.data.message)
              return;
          })
        }

      })
  }

  async function GetTicket() {
    if (CodigoBarra == '') {
      toast.error('Preencha todos os campos!')
      return;
    }

    setLoader(true)

    const obj = {
      username: localStorage.getItem('taxNumber'),
      password: localStorage.getItem('identifier'),
      mac: localStorage.getItem('deviceId'),
      login: localStorage.getItem('login'),
      token: localStorage.getItem('fiduciaToken')
    }

    await api.post('/login/token', obj)
      .then(async function (result) {
        localStorage.setItem('token', result.data.token);

        const api2 = await axios.create({
            headers: {
                'Authorization': result.data.token,
                'device': localStorage.getItem('deviceId')
            },
            baseURL: apiUrl
        })

        var split = CodigoBarra.split('')[0];

        const body = {
          'taxNumber': localStorage.getItem('taxNumber'),
          'barCode': CodigoBarra
      }

      const bank = localStorage.getItem('bank')

        if(bank === '450'){
          if (split == '8') { // Boleto de concessionária
            let getInfos = await api2.post('/payment/getinfosbar', body)
              .catch(err => {
                  setLoader(false)
                  toast.error(err.response.data.message)
                  return;
              })
            
            console.log('getInfos', res);
            var res = JSON.parse(getInfos.data.data.Infos);
            setResponse(res)
            let vencList = response.DueDate.toString().split('T')[0].split('-');
            console.log(vencList);
            
            setDue(vencList[2]+'/'+vencList[1]+'/'+vencList[0]);
            setPay(true);
            setLoader(false)
            setInfo(true)
            
          } else { // boleto de banco (CIP)
            var getInfos = await api2.post('/payment/getinfos', body)
            .catch(err => {
              setLoader(false)
              toast.error(err.response.data.message)
              return;
            })
            console.log("getInfos ", getInfos);
  
            var res = JSON.parse(JSON.parse(getInfos.data.data.body).Infos);
            console.log('getInfos', res);
            setResponse(res)
            console.log(response)
            console.log(res['DueDate'])
            let vencList = res['DueDate'].split('T')[0].split('-');
            console.log(vencList);
            setDue(vencList[2]+'/'+vencList[1]+'/'+vencList[0]);
            setPay(true);
            setLoader(false)
            setInfo(true)
          }
        } else {
          let payload = {
            token: localStorage.getItem('fiduciaToken'),
            digitable: CodigoBarra
          }

          let getInfos = await api2.post('/payment/fiducia/getinfos', payload)
          .catch(err => {
            setLoader(false)
            toast.error(err.response.data.message)
            return;
          })
          console.log("getInfos ", getInfos);

          let barCodeInfo = {
            BeneficiaryName : getInfos.data.data.registerData.recipient,
            UpdatedValue: getInfos.data.data.value,
            digitable: getInfos.data.data.digitable,
            barcode: getInfos.data.data.barcode,
            value: getInfos.data.data.value,
            originalValue: getInfos.data.data.registerData.originalValue,
            valueWithDiscount: getInfos.data.data.registerData.totalWithDiscount,
            valueWithAdditional: getInfos.data.data.registerData.totalWithAdditional,
            type: getInfos.data.data.type,
            dueDate: getInfos.data.data.dueDate,
            assignor: getInfos.data.data.assignor,
            recipient: getInfos.data.data.registerData.recipient,
            transactionIdAuthorize: getInfos.data.data.transactionId,
          }
          console.log("barCodeInfo ", barCodeInfo);

          setResponse(barCodeInfo)
          setDue(moment(getInfos.data.data.registerData.payDueDate).format("DD/MM/YYYY"));
          setPay(true);
          setLoader(false)
          setInfo(true)
        }

      })
  }

  return (
    <>
      <div className="pagar-boleto">
        <div className="container">
          <div className="boleto row mb-3">
            <div className="col-xl-8 d-flex align-items-end">
              <p className="mb-0 title">Boleto</p>
            </div>

            {/* <div className="col-xl-4 d-flex justify-content-end gap-3">
              <Link to={"/pagina-boleto"}>
                <button style={{ backgroundColor: "var(--secondary-color)" }}>Boletos</button>
              </Link>
            </div> */}
            {/*<div className="gera-boleto col-xl-2">
              <Link to={"/pagina-novo-boleto"}>
                <button>Novo Boleto</button>
              </Link>
            </div>



            <div className="col-xl-2">
              <Link to={"/pagina-pagar-boleto"}>
                <button>Pagar</button>
              </Link>
            </div>
            */}
          </div>

          <div className="transferencia">
            <div className="row">
              <div className="scroll col-xl-6">
                <div>
                  <h1>Pagar Boleto</h1>
                </div>

                <div>
                  <h2>Digite o número do código de barras para realizar o pagamento</h2>
                </div>

                <div>
                  <input
                    type="text"
                    placeholder="Digite o Codigo de barras"
                    value={CodigoBarra}
                    onChange={e => { setCodigoBarra(e.target.value) }}
                  />
                </div>
                <div className="botao-confirmar">
                  <button onClick={e => GetTicket()}>Localizar</button>
                </div>

              </div>

              <div className="col-xl-6">
                <div className="resumo-transferencia">
                  <div>
                    <h1>Resumo do Boleto</h1>
                  </div>

                  <div style={info ? { 'display': 'block' } : { 'display': 'none' }}>
                      <div>
                          <p>Beneficiário</p>
                          <h4>{response.BeneficiaryName}</h4>
                      </div>

                      <div>
                          <p>Valor</p>
                          <h4>{response.UpdatedValue}</h4>
                      </div>

                      <div>
                          <p>Vencimento</p>
                          <h4>{due}</h4>
                      </div>

                      <div>
                          <p>Quando será pago</p>
                          <h4>Hoje</h4>
                      </div>
                  </div>

                  <div style={loader ? { 'display': 'block', 'margin': 'auto', 'marginTop': '20px' } : { 'display': 'none' }}>
                      <BeatLoader color={"var(--secondary-color)"} loading={loader} css={override} size={20} />
                  </div>
                </div>

                <div className="botao-confirmar">
                    <button
                        style={pay ? { 'display': 'block' } : { 'display': 'none' }}
                        onClick={() => confirm()}>Confirmar pagamento
                    </button>

                    <button
                        style={loader ? { 'display': 'block' } : { 'display': 'none' }}
                        onClick={() => toast.info('Requisição em processamento!')} >
                        <BeatLoader color={"#fff"} loading={loader} css={override} size={10} />
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={isOpenModal}
        className={"password-modal"}
        overlayClassName={"overlay-password-modal"}
      >
        <button
          className="modal-close-button"
          onClick={() => setIsOpenModal(false)}
        >
          <MdOutlineClose color="white" size={24} />
        </button>
        <label>Digite sua senha</label>
        <input
          type={"password"}
          value={transationalPassword}
          onChange={(e) => setTransationalPassword(e.target.value)}
        />
        <button
          className="modal-confirm-button"
          onClick={() => PayTicket()}
        >
          Confirmar
        </button>
      </ReactModal>
    </>
  );
}
