import React, { useState } from "react";

import "../senha-pagamento-fatura/Style.css"
import api from "../../../../../services/api"

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { Link, useNavigate } from "react-router-dom";


export default function () {

    const [senha, setSenha] = useState('')
    const navigate = useNavigate();

    async function ConfirmPassword() {
        if (senha == '') {
            toast.error('Preencha o campo Senha')
            return;
        }

        const obj = {
            username: localStorage.getItem('taxNumber'),
            password: localStorage.getItem('identifier'),
            mac: localStorage.getItem('deviceId'),
            login: localStorage.getItem('login')
        }

        await api.post('/login/token', obj)
            .then(result => {
                localStorage.setItem('token', result.data.token)
            })

        await api.post('/login/web', {
            'password': obj.password
        })
            .catch(err => {
                console.log(err)
                toast.error('Erro por favor tente novamente !')
            })

    }


    return (
        <div className="senha-pagamento-fatura">
            <div className="container">
                <div className="titulo">
                    <p>Senha</p>
                </div>

                <div className="fundo-senha">
                    <div>
                        <h1>Digite sua senha para confirma seu pagamento</h1>
                    </div>

                    <div>
                        <p>Senha</p>
                        <input type="text"
                            placeholder="Digite sua Senha"
                            value={senha}
                            onChange={e => { setSenha(e.target.value) }}
                        />

                    </div>

                    <div>
                        <Link to={"/pagamento-efetuado"}>
                            <button onClick={e => ConfirmPassword}>Confirmar</button>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}