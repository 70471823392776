import React from "react";

import "./Style.css"

import { BiChevronRight } from "react-icons/bi"
import { FaMoneyBillWave } from "react-icons/fa"
import { FaPix } from "react-icons/fa6";
import { BiTransfer } from "react-icons/bi";
import { entryTypes } from "../../../services/enums";
import moment from "moment";

export default function (props) {
    let displayType;
    console.log("props.data.description ", props.data.subType);

    if(localStorage.getItem('bank') === '450'){
        displayType = entryTypes.filter((type)=> props.data.type === type.key )[0].displayValue;
    } else {
        displayType = entryTypes.filter((type)=> props.data.subType === type.key )[0].displayValue;
    }

    const setIcon = () =>{
        switch(displayType){
            case "Pix Enviado": {
                return <FaPix size={22}/>
            }
            case "Pix Recebido": {
                return <FaPix size={22} color="#008000"/>
            }
            case "Transferencia por TED": {
                return <BiTransfer size={22}/>
            }
            case "Recebimento por TED": {
                return <BiTransfer size={22} color="#008000"/>
            }
            case "Pagamento de Boleto": {
                return <FaMoneyBillWave size={22}/>
            }
            case "Taxa de Recebimento de Pix": {
                return <FaMoneyBillWave size={22}/>
            }
            case "Pagamento de Boleto": {
                return <FaMoneyBillWave size={22}/>
            }
            default :{
                return <FaMoneyBillWave size={22}/>
            }
        }
    }
    return (
        <div className="row extrato-item" onClick={() => { props.handleOnClick(props.data) }}>
            <div className="FaMoneyBillWave col-xl-2">
                { setIcon() }
            </div>

            <div className="col-xl-6">
                <div>
                    <p className="entryValue">{displayType}</p>
                    <p className="entryValue" style={parseFloat(props.data.value) < 0 ? { color: 'red' } : { color: 'green' }}>R$ {parseFloat(props.data.value).toFixed(2)}</p>
                </div>
            </div>
            <div className="col-xl-2">
                <p>{moment(props.data.date).format('DD/MM HH:mm')}</p>
            </div>

            <div className="BiChevronRight col-xl-2">
                <BiChevronRight className="seta-esquerda" />
            </div>
            <hr />
        </div>
    )
}