import React from "react";

import "./Style.css"

import MenuLateral from "../../../components/menu-lateral/MenuLateral"
import Header from "../../../components/header/Header"
import BoasVindas from "../../../components/boas-vindas/BoasVindas"
import GerarCodigo from "../../../components/acesso-rapido/pix/gerar-codigo-pix/GerarCodigoPix"

export default function(){
    return(
        <div>
            <MenuLateral/>
            <Header/>
            <div className="pagina-gerar-codigo">
                <BoasVindas/>
                <GerarCodigo/>
            </div>

        </div>
    )
}