import React from "react";

import "../Cancel-block-card/Style.css"

import { getImage } from '../../../../services/getAssets'

export default function () {
    return (
        <div className="bloquear-cartao">
            <div className="container">
                <p>Cancelar e bloquear cartão</p>
                <div className="fundo" >
                    <div>
                        <h1>Cartão de debito</h1>
                    </div>
                    <div className="bloquear">
                        <div className="row">
                            <div className="img-card col-xl-4" >
                                <img src={getImage('cartao.png')} alt="Cartão de debito" />
                            </div>

                            <div className="inf col-xl-2" >
                                <h2>Nome</h2>
                                <p>Leandro fontes</p>
                                <h2>Numero</h2>
                                <p>5502 0877 2245 4552</p>
                                <div className="botao-confirmar-red">
                                    <button>Cancelar Cartão</button>
                                </div>

                            </div>
                            <div className="inf col-xl-2" >
                                <h2>Validade</h2>
                                <p>08/2029</p>
                                <h2>Função</h2>
                                <p>Debito</p>
                                <div className="botao-confirmar-black">
                                    <button>Bloquear Cartão</button>
                                </div>

                            </div>
                        </div>
                        <div />
                    </div>
                    <div>
                        <h1>Cartão de Crédito</h1>
                    </div>
                    <div className="bloquear">
                        <div className="row">
                            <div className="img-card col-xl-4" >
                                <img src={getImage('cartao.png')} alt="Cartão de debito" sizes="width:10" />
                            </div>

                            <div className="inf col-xl-2" >
                                <h2>Nome</h2>
                                <p>Leandro fontes</p>
                                <h2>Numero</h2>
                                <p>5502 0877 2245 4552</p>
                                <div className="botao-confirmar-red">
                                    <button>Cancelar Cartão</button>
                                </div>
                            </div>
                            <div className="inf col-xl-2" >
                                <h2>Validade</h2>
                                <p>08/2029</p>
                                <h2>Função</h2>
                                <p>Credito</p>
                                <div className="botao-confirmar-black">
                                    <button>Bloquear Cartão
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div />
                    </div>
                </div>
            </div>
        </div>
    )
}