import React, { useEffect } from "react";

import "../../boleto/resumo-boleto/Style.css";

import { Link, useLocation } from "react-router-dom";

export default function () {

  const location = useLocation()

  useEffect(() => {
    var x = JSON.parse(location.state.boleto).CodigoBarra.TypefulLine
    console.log(x)
  }, [])

  return (
    <div>
      <div className="resumo-boleto">
        <div className="container">
          <div className="boleto row">
            <div className="col-xl-6">
              <p>Boleto </p>
            </div>

            <div className="gera-boleto col-xl-2">
              <Link to={"/pagina-novo-boleto"}>
                <button>Novo Boleto</button>
              </Link>
            </div>


            <div className="col-xl-2">
              <Link to={"/pagina-boleto"}>
                <button>Boletos</button>
              </Link>
            </div>
          </div>

          <div className="transferencia">
            <div className="row">

              <div className="col-xl-6">
                <div className="resumo-transferencia">
                  <div>
                    <h1>Resumo do Boleto</h1>
                  </div>

                  <div>
                    <h2>{location.state.customerName}</h2>
                  </div>

                  <div>
                    <p>CPF</p>
                    <h4>{location.state.customerTaxNumber}</h4>
                  </div>

                  <div>
                    <p>Data de vencimento</p>
                    <h4>{new Date(location.state.dueDate).toLocaleDateString()}</h4>
                  </div>
                  <div>
                    <p>Valor</p>
                    <h4>R$ {location.state.totalValue}</h4>
                  </div>
                  <div style={{ marginTop: '15px' }}>
                    <p>Status do boleto</p>
                  </div>
                  <div>
                    <p>Status</p>
                    <h4>-</h4>
                  </div>
                  <div>
                    <p>Valor pago</p>
                    <h4>R$ {location.state.totalValue}</h4>
                  </div>
                  <div>
                    <p>Data do pagamento</p>
                    <h4>-</h4>
                  </div>
                  <div>
                    <p>Código do boleto</p>
                    <h4>{JSON.parse(location.state.boleto).CodigoBarra.TypefulLine}</h4>
                  </div>

                  <div className="row">
                    <div className="baixar-boleto col-xl-6" >
                      <button onClick={() => window.open(`https://techtomarket.com.br/boleto/boleto_sicredi.php?id=${location.state.id}`)}>Baixar boleto</button>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
