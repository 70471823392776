import React from "react";

import "../fatura-cartao-credito/Style.css"
import { Link } from "react-router-dom";

import DescExtrato from "../../../../extrato/desc-extrato/DescExtrato"

import { MdOutlineWatchLater } from "react-icons/md"
import { BiChevronRight } from "react-icons/bi"
import { FaMoneyBillWave } from "react-icons/fa"
import { RiHandbagLine } from "react-icons/ri"
import { FiShoppingCart } from "react-icons/fi"
import { RiNetflixFill } from "react-icons/ri"
import { FaGooglePlay } from "react-icons/fa"
import { TiDocumentText } from "react-icons/ti"

export default function () {
    return (
        <div className="cartao-de-credito">
            <div className="container">
                <div>
                    <p>Cartão de Credito</p>
                </div>

                <div className="fatura-mes">
                    <div className="row">
                        <div className="col-xl-6">
                            <div>
                                <h1>Sua Fatura Refente ao mês de <strong>Fevereiro</strong></h1>
                                <p>Vencimento 20 Fevereiro de 2022</p>
                                <p className="valor">R$ 333,50</p>
                                <p className="pagamento">Pagamento recebido, 09/02/2022 - 13:54 </p>
                            </div>

                            <div>
                                <h2>Fatura Referente ao Mês de Fevereiro</h2>
                            </div>

                            <div className="scroll">
                                <div className="lancamento">
                                    <div className=" row">
                                        <div className="MdOutlineWatchLater col-xl-2">
                                            <MdOutlineWatchLater className="relogio" />
                                        </div>

                                        <div className="col-xl-5">
                                            <p>Financiamento Carro 36/36</p>
                                        </div>

                                        <div className="col-xl-3">
                                            <p>-R$800,00</p>
                                        </div>

                                        <div className="BiChevronRight col-xl-2">
                                            <BiChevronRight className="seta-esquerda" />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <p>8 de Novembro</p>
                                </div>

                                <div className="lancamento">
                                    <div className=" row">
                                        <div className="MdOutlineWatchLater col-xl-2">
                                            <MdOutlineWatchLater className="relogio" />
                                        </div>

                                        <div className="col-xl-5">
                                            <p>Financiamento Carro 35/36</p>
                                        </div>

                                        <div className="col-xl-3">
                                            <p>-R$800,00</p>
                                        </div>

                                        <div className="BiChevronRight col-xl-2">
                                            <BiChevronRight className="seta-esquerda" />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <h1>Últimos Lançamentos</h1>
                                </div>

                                <div className="ultimos-lancamento">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <p>20 de setembro</p>
                                        </div>

                                        <div className="col-xl-6">
                                            <p>Saldo do dia R$ 700,00</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="lancamento">
                                    <div className=" row">
                                        <div className="FaMoneyBillWave col-xl-2">
                                            <FaMoneyBillWave className="dinheiro" />
                                        </div>

                                        <div className="col-xl-5">
                                            <p>Depósito Alex Teza</p>
                                        </div>

                                        <div className="deposito col-xl-3">
                                            <p>R$500,00</p>
                                        </div>

                                        <div className="BiChevronRight col-xl-2">
                                            <BiChevronRight className="seta-esquerda" />
                                        </div>

                                        <hr></hr>

                                        <div className="RiHandbagLine col-xl-2">
                                            <RiHandbagLine className="compra" />
                                        </div>

                                        <div className=" col-xl-5">
                                            <p>Magazine Luiza</p>
                                        </div>

                                        <div className="col-xl-3">
                                            <p>- R$ 1.700,00</p>
                                        </div>

                                        <div className="BiChevronRight col-xl-2">
                                            <BiChevronRight className="seta-esquerda" />
                                        </div>

                                        <hr></hr>

                                        <div className="FiShoppingCart col-xl-2">
                                            <FiShoppingCart className="mercado" />
                                        </div>

                                        <div className=" col-xl-5">
                                            <p>Magazine Luiza</p>
                                        </div>

                                        <div className="col-xl-3">
                                            <p>- R$ 2.500,00</p>
                                        </div>

                                        <div className="BiChevronRight col-xl-2">
                                            <BiChevronRight className="seta-esquerda" />
                                        </div>

                                        <hr></hr>
                                    </div>
                                </div>

                                <div className="ultimos-lancamento">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <p>19 de Novembro</p>
                                        </div>

                                        <div className="col-xl-6">
                                            <p>Saldo do dia R$ 5.595,00</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="lancamento">
                                    <div className=" row">
                                        <div className="RiNetflixFill col-xl-2">
                                            <RiNetflixFill className="netflix" />
                                        </div>

                                        <div className="col-xl-5">
                                            <p>Netflix</p>
                                        </div>

                                        <div className="deposito col-xl-3">
                                            <p>- R$ 19,90</p>
                                        </div>

                                        <div className="BiChevronRight col-xl-2">
                                            <BiChevronRight className="seta-esquerda" />
                                        </div>

                                        <hr></hr>

                                        <div className="FaGooglePlay col-xl-2">
                                            <FaGooglePlay className="google-play" />
                                        </div>

                                        <div className="col-xl-5">
                                            <p>Google Play</p>
                                        </div>

                                        <div className="deposito col-xl-3">
                                            <p>- R$ 35,10</p>
                                        </div>

                                        <div className="BiChevronRight col-xl-2">
                                            <BiChevronRight className="seta-esquerda" />
                                        </div>

                                        <hr></hr>

                                    </div>
                                </div>
                            </div>

                            <div className="botoes">
                                <div className="row">
                                    <div className="pagar col-xl-6">
                                        <Link to={"/senha-pagamento-fatura"}>
                                        <button>Pagar</button>
                                        </Link>
                                    </div>

                                    <div className="col-xl-6">
                                        <button>Gerar PDF</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6">

                            <DescExtrato />

                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}