import React, { useEffect, useState } from "react";

import "../boleto/Style.css";

import { TiDocumentText } from "react-icons/ti";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link } from "react-router-dom";
import api, { apiUrl } from "../../../services/api";
import axios from 'axios';
import { toast, ToastContainer } from "react-toastify";

export default function () {
  const [list, setList] = useState([])

  async function getBoletos() {
    const obj = {
      username: localStorage.getItem('taxNumber'),
      password: localStorage.getItem('identifier'),
      mac: localStorage.getItem('deviceId'),
      login: localStorage.getItem('login')
    }

    await api.post('/login/token', obj)
      .then(async function(result) {
        localStorage.setItem('token', result.data.token)

        const api2 = await axios.create({
          headers: {
              'Authorization': result.data.token,
              'device': localStorage.getItem('deviceId')
          },
          baseURL: apiUrl
        })

        var resp = await api2.post('/boleto/list', {
          'accountId': localStorage.getItem('accountId')
        })
          .catch(err => {
            return toast.error(err.response.data.message)
          })
    
        var lst = resp.data.data
        setList(lst)
      })
  }

  useEffect(() => {
    getBoletos()
  }, [])

  return (
    <div className="novo-boleto">
      <div className="container">
        <div className="boleto row mb-3">
          <div className="col-xl-8 d-flex align-items-end">
            <p className="mb-0 title">Boleto</p>
          </div>

          <div className="col-xl-4 d-flex justify-content-end gap-3">
            <Link to={"/pagina-novo-boleto"}>
              <button style={{ backgroundColor: "var(--secondary-color)" }}>Novo Boleto</button>
            </Link>
            <Link to={"/pagina-pagar-boleto"}>
              <button style={{ backgroundColor: "var(--secondary-color)" }}>Pagar Boleto</button>
            </Link>
          </div>

        </div>

        <div className="historico-boleto">
          <div>
            <h1>Histórico</h1>
          </div>

          <div className={"head-boleto"}>
            <div className="row">
              <div className={"headTable col-xl-4"}>
                <p>Pagador</p>
              </div>

              <div className={"headTable col-xl-2"}>
                <p>Dt. Vencimento</p>
              </div>

              <div className={"headTable col-xl-1"}>
                <p>Valor</p>
              </div>

              <div className={"headTable col-xl-2"}>
                <p>Dt. Pagamento</p>
              </div>

              <div className={"headTable col-xl-1"}>
                <p>Pago</p>
              </div>
              <div className={"headTable col-xl-1"}>
              </div>
            </div>
          </div>

          {list.map((boleto, index) =>
            <Link key={boleto.id} to="/pagina-resumo-boleto" state={boleto} >
              <div className={index % 2 == 0 ? "descricao-boleto-4" : "descricao-boleto"}>
                <div className="row">
                  <div className={index % 2 == 0 ? "descricao-4 col-xl-4" : "descricao col-xl-4"}>
                    <p>{boleto.customerName}</p>
                  </div>

                  <div className={index % 2 == 0 ? "datas-4 col-xl-2" : "datas col-xl-2"}>
                    <p>{new Date(boleto.dueDate).toLocaleDateString()}</p>
                  </div>

                  <div className={index % 2 == 0 ? "valores-4 col-xl-1" : "valores col-xl-1"}>
                    <p>R$ {boleto.totalValue.replaceAll('.', ',')}</p>
                  </div>

                  <div className={index % 2 == 0 ? "datas-4 col-xl-2" : "datas col-xl-2"}>
                    <p>{boleto.paymentDate ?? '-'}</p>
                  </div>

                  <div className={index % 2 == 0 ? "valores-4 col-xl-1" : "valores col-xl-1"}>
                    <p>{boleto.paidValue ?? '-'}</p>
                  </div>

                  <div className="icon-end col-xl-1">
                    <BiSearchAlt2 />
                  </div>
                </div>
              </div>
            </Link>
          )}

        </div>
      </div>

      <ToastContainer />
    </div>
  );
}
