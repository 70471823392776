import React, { useState, useEffect } from "react";

import "../boas-vindas/Style.css";

import { BiSearchAlt2 } from "react-icons/bi";

export default function () {
  const [name, setName] = useState('')

  useEffect(() => {
    setName(localStorage.getItem('name'))
  }, [])

  return (
    <div className="boas-vindas">
      <div className="container">
        <div className="row">
          <div className="col-xl-7">
            <h1>
              Olá <strong>{name}</strong>, é bom ver você aqui ;)
            </h1>
            <h2>O que vamos fazer hoje?</h2>
          </div>

          
        </div>
      </div>
    </div>
  );
}
