import React, { useEffect, useState } from "react";

import "../desc-extrato/Style.css"
import moment from 'moment';
import 'moment/locale/pt-br';
import { banco_codigo } from '../../../assets/banco_codigo'
import { getImage } from "../../../services/getAssets";
import { MdDownload } from 'react-icons/md'

import { PDFDocument } from 'pdf-lib';
import html2canvas from 'html2canvas';

export default function (props) {
    console.log("props ", props.data);
    const name = localStorage.getItem('name');

    const imprimirExtrato = async () => {
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([550, 600]);

        const canvas = await html2canvas(document.getElementById('desc-extrato'));
        const imageData = canvas.toDataURL('image/png');
        const image = await pdfDoc.embedPng(imageData);

        const imageWidth = 550;
        const imageHeight = (canvas.height * imageWidth) / canvas.width;
        page.drawImage(image, {
            x: 0,
            y: 600 - imageHeight,
            width: imageWidth,
            height: imageHeight,
        });

        const pdfBytes = await pdfDoc.save();

        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        const a = document.createElement('a');
        a.href = pdfUrl;
        a.download = 'extrato.pdf';
        a.target = '_blank';
        a.click();
    }

    return (
        <div className="d-flex flex-column desc-info">
            {props.data ?
                <>
                    <div className="desc-extrato" id="desc-extrato">
                        <div className="desc-header">
                            <img src={getImage('logo-preto.png')} alt="" />
                            <h2>Detalhe do comprovante</h2>
                        </div>
                        <div className="desc-body">
                            <label>Valor: </label>
                            <p className="value-area">R$ <strong>{parseFloat(props.data.value).toFixed(2)}</strong></p>
                            <label>Data da transação: </label>
                            <p>{moment(props.data.date).locale('pt-br').format("DD/MM/YYYY")}</p>

                            <div>
                                <h1>De:</h1>
                                {props.data.type === "Recebimento por TED" || props.data.type === "Pix Recebido" ?
                                    <p>{props.data.name ? props.data.name : "Não informado"}</p>
                                    :
                                    <p>{name}</p>
                                }
                                <div>
                                    <p>{(banco_codigo.find((item) => item.value === props.data.bank || item.label === props.data.bank)).value} - {(banco_codigo.find((item) => item.value === props.data.bank || item.label === props.data.bank)).label || ""}</p>
                                    <p>Agência: {props.data.bankBranch}</p>
                                    <p>Conta: {props.data.bankAccount + "-" + props.data.bankAccountDigit}</p>
                                </div>
                            </div>
                        </div>
                        <div className="desc-footer">
                            <h1>Para:</h1>
                            {props.data.type === "Recebimento por TED" || props.data.type === "Pix Recebido" ?
                                <p>{name}</p>
                                :
                                <p>{props.data.name ? props.data.name : "Não informado"}</p>
                            }
                            <p>{(banco_codigo.find((item) => item.value === props.data.toBank || item.label === props.data.toBank)).value} - {(banco_codigo.find((item) => item.value === props.data.toBank || item.label === props.data.toBank)).label || ""}</p>
                            <p>Agência: {props.data.toBankBranch}</p>
                            <p>Conta: {props.data.toBankAccount + "-" + props.data.toBankAccountDigit}</p>
                        </div>
                    </div>
                    <div className="print-area">
                        <button onClick={imprimirExtrato}>
                            <MdDownload size={22} />
                            Baixar
                        </button>
                    </div>
                </>
                : ""
            }
        </div>
    )
}