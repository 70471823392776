import React from "react";

import "../resumo-boleto/Style.css";

import Header from "../../../components/header/Header";
import MenuLateral from "../../../components/menu-lateral/MenuLateral";
import BoasVindas from "../../../components/boas-vindas/BoasVindas";
import ResumoBoleto from "../../../components/acesso-rapido/boleto/resumo-boleto/index";

export default function () {
  return (
    <div>
      <Header />
      <MenuLateral />
      <div className="resumo-boleto">
        <BoasVindas />
        <ResumoBoleto />
      </div>
    </div>
  );
}
