import React from "react"

import "../opcoes-cartao-credito/Style.css"

import { Link } from "react-router-dom"

export default function(){
    return(
        <div className="opcoes-credito">
            <div className="container">
  
                <div className="titulo">
                    <p>Cartão de Credito</p>
                </div>

                <div className="selecione-opcao">
                    <div>
                        <h1>Cartão de Credito</h1>
                        <p>Selecione a opção desejada</p>
                    </div>

                    <Link to={"/fatura-cartao-credito"}>
                        <div className="bloco">
                            <div>
                                <h2>Ver Fatura</h2>
                                <p>Visualizar a fatura referente ao mês</p>
                            </div>
                        </div>
                    </Link>

                    <Link to={"/bloquear-cartao"}>
                        <div className="bloco">
                            <h2>Bloqueio Temporario</h2>
                            <p>Ao selecionar essa opçao você podera fazer o bloqueio temporario do seu cartão e desfazer quando quiser.</p>
                        </div>

                    </Link>

                    <Link to={"/codigo-senha"}>
                        <div className="bloco">
                            <div>
                                <h2>Ver Senha do Cartão</h2>
                                <p>Selecione para visualizar a senha do seu cartão.</p>
                            </div>
                        </div>
                    </Link>

                    <Link to={"/bloquear-cartao"}>
                        <div className="bloco">
                            <div>
                                <h2>Cancelar Cartão</h2>
                                <p>Escolha essa opçao em caso de perda ou roubo.</p>
                            </div>
                        </div>
                    </Link>

                    <Link to={"/ajuste-limite-credito"}>
                        <div className="bloco">
                            <div>
                                <h2>Ajustar Limite </h2>
                                <p>Ajuste seu limite do Cartão de Credito.</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
     
    )
}

