import React from "react";

import MenuLateral from "../../../../components/menu-lateral/MenuLateral";
import Header from "../../../../components/header/Header";
import BoasVindas from "../../../../components/boas-vindas/BoasVindas";
import PagamentoEfetuado from "../../../../components/acesso-rapido/cartoes/cartao-credito/pagamento-efetuado/PagamentoEfetuado";

export default function(){
    return(
        <div>
            <MenuLateral/>
            <Header/>
            <div>
                <BoasVindas/>
                <PagamentoEfetuado/>
            </div>
        </div>
    )
}