import React from "react";

import MenuLateral from "../../../../components/menu-lateral/MenuLateral";
import Header from "../../../../components/header/Header";
import BoasVindas from "../../../../components/boas-vindas/BoasVindas";
import AjusteLimite from "../../../../components/acesso-rapido/cartoes/cartao-credito/ajuste-limite-credito"

export default function () {
    return (
        <div>
            <MenuLateral/>
            <Header/>
            <div>
                <BoasVindas/>
                <AjusteLimite/>
            </div>
        </div>
    )
}