import React from "react";

import MenuLateral from "../../../components/menu-lateral/MenuLateral";
import Header from "../../../components/header/Header";
import BoasVindas from "../../../components/boas-vindas/BoasVindas";
import BloqueiCartao from "../../../components/acesso-rapido/cartoes/Cancel-block-card/index"

export default function(){
    return(
        <div>
            <MenuLateral/>
            <Header/>
            <div>
                <BoasVindas/>
                <BloqueiCartao/>
            </div>
        </div>
    )
}