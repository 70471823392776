import React, { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import api, { apiUrl } from "../../../services/api";
import axios from "axios";

import "../transferir/Style.css";
import IntlCurrencyInput from "react-intl-currency-input";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import { banco_codigo } from "../../../assets/banco_codigo";
import ReactModal from "react-modal";
import { MdOutlineClose } from "react-icons/md";
import { sendTedFiducia, sendTedFitBank } from "../../../services/ted.service";
import moment from "moment/moment";

export default function () {
  const [valor, setValor] = useState(0);
  const [agencia, setAgencia] = useState("");
  const [conta, setConta] = useState("");
  const [digito, setDigito] = useState("");
  const [tipoConta, setTipoConta] = useState("")
  const [cpf, setCpf] = useState("");
  const [nome, setNome] = useState("");
  const [banco, setBanco] = useState("");
  const [description, setDescription] = useState("");
  const [response, setResponse] = useState({});
  const [transationalPassword, setTransationalPassword] = useState("");

  const [receipt, setReceipt] = useState(false);
  const [preReceit, setPreReceipt] = useState();
  const [hasPreReceipt, setHasPreReceipt] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();

    setValor(value); // value without mask (ex: 1234.56)
    console.log(maskedValue); // masked value (ex: R$1234,56)
  };

  const confirm = () => {
    let bank = localStorage.getItem("bank");
    if (bank != "450") {
      setIsOpenModal(true);
    } else {
      sendTransfers();
    }
  };

  const checkpersonType = () => {
    let cleanCpf = cpf.replace('.','').replace('-','')
    if(cleanCpf.length === 11){
      return "F"
    }else if(cleanCpf.length === 14){
      return "J"
    } else{
      return null
    }
  }

  async function sendTransfers() {
    setLoader(true);

    let bank = localStorage.getItem("bank");
    
    if (!transationalPassword && bank !== "450") {
      toast.error("Digite a senha");
      return;
    }
    const tipoPessoa = checkpersonType();
    if(!tipoPessoa){
      toast.error("Cpf ou Cnpj inválido!");
      return;
    }

    setIsOpenModal(false);
    setPreReceipt();
    setHasPreReceipt(false);
    const date = new Date(Date.now());

    const body = {
      fromTaxNumber: localStorage.getItem("taxNumber"),

      toName: preReceit.nome,
      toTaxNumber: preReceit.cpf,
      toBank: preReceit.banco,
      toBankBranch: preReceit.agencia,
      toBankAccount: preReceit.conta,
      toBankAccountDigit: preReceit.digito,

      value: preReceit.valor,
      rateValue: "0",

      transferDate: date.toISOString(),
      description: preReceit.description,
    };

    console.log(body);

    const obj = {
      username: localStorage.getItem("taxNumber"),
      password: localStorage.getItem("identifier"),
      mac: localStorage.getItem("deviceId"),
      login: localStorage.getItem("login"),
    };

    await api.post("/login/token", obj).then(async function (result) {
      localStorage.setItem("token", result.data.token);

      try{
        const bank = localStorage.getItem("bank");
  
        if (bank === "450") {
          const tedResponse = await sendTedFitBank(body);
          console.log("tedResponse ", tedResponse);
  
          let data = tedResponse.data;
          console.log(data);
          toast.success("Transferência enviada!");
          setHasPreReceipt(false);
          setPreReceipt(null);
          setResponse(data);
          setReceipt(true);
          setLoader(false);
          clearInputs();
          
        } else {
          console.log("tipoPessoa ", tipoPessoa);
          const tedPayload = {
            valor: preReceit.valor,
            transational_password: transationalPassword,
            tipo_pessoa_destino: tipoPessoa,
            cpfcnpj_destino: preReceit.cpf,
            banco_destino: preReceit.banco,
            agencia_destino: preReceit.agencia.replaceAll('_',''),
            conta_destino: preReceit.conta.replaceAll('_','') + "-" + preReceit.digito.replaceAll('_',''),
            nome_razaosocial_destino: preReceit.nome,
            tipo_conta_destino: tipoConta,
            finalidade: 10,
            historico: description,
            data_agendamento: null,
            fromTaxNumber: localStorage.getItem("taxNumber"),
            token: localStorage.getItem('fiduciaToken')
          };
  
          const tedResponse = await sendTedFiducia(tedPayload);
          console.log("tedResponse ", tedResponse);
  
          let data = tedResponse.data;
          console.log(data);
          toast.success("Transferência enviada!");
          setHasPreReceipt(false);
          setPreReceipt(null);
          setResponse(data);
          setReceipt(true);
          setLoader(false);
          clearInputs();
        }

      } catch(err) {
        setLoader(false)
      }
    });
  }

  const getInfos = async () => {
    setLoader(true);

    if (
      valor == "" ||
      agencia == "" ||
      conta == "" ||
      cpf == "" ||
      nome == "" ||
      banco == "" ||
      digito == ""
    ) {
      toast.error("Preencha todos os campos !");
      setLoader(false);
      return;
    }

    setHasPreReceipt(true);
    setPreReceipt({
      valor,
      agencia,
      conta,
      digito,
      cpf,
      nome,
      banco,
      description,
    });

    setLoader(false);
  };

  async function clearInputs() {
    setValor(0);
    setAgencia("");
    setConta("");
    setDigito("");
    setCpf("");
    setNome("");
    setBanco("");
    setDescription("");
  }

  return (
    <>
      <div className="transferencia-ted">
        <div className="container">
          <div className="botao row">
            <div className="col-xl-8">
              <p>TED</p>
            </div>
          </div>

          <div className="transferencia">
            <div className="row">
              <div className="col-xl-6 scroll">
                <div>
                  <h1>Nova transferência</h1>
                </div>

                <div>
                  <h2>Qual valor você deseja transferir?</h2>
                </div>

                <div>
                  <p>Valor</p>
                  <IntlCurrencyInput
                    currency="BRL"
                    config={currencyConfig}
                    onChange={handleChange}
                    placeholder="Digite o valor a ser transferido"
                    value={valor}
                    // onChange={e => { setValor(e.target.value) }}
                  />
                </div>

                <div>
                  <h2>Preencha os dados de quem irá receber a tranferência</h2>
                </div>

                <div>
                  <p>Banco</p>
                  <select
                    value={banco}
                    onChange={(e) => setBanco(e.target.value)}
                  >
                    <option>Selecione um Banco</option>
                    {banco_codigo.map((item) => (
                      <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <p>Agência</p>
                  <InputMask
                    mask="9999"
                    placeholder="Digite agência de destino"
                    value={agencia}
                    onChange={(e) => {
                      setAgencia(e.target.value);
                    }}
                  />
                </div>

                <div className="numero-conta">
                  <div className="row">
                    <div className="conta col-xl-6">
                      <p>Conta</p>
                      <InputMask
                        mask="999999999"
                        placeholder="Digite a conta de destino"
                        value={conta}
                        onChange={(e) => {
                          setConta(e.target.value);
                        }}
                      />
                    </div>

                    <div className="conta col-xl-6">
                      <p>Dígito</p>
                      <InputMask
                        mask="9"
                        placeholder="Digite o dígito da conta"
                        value={digito}
                        onChange={(e) => {
                          setDigito(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div>
                <p>Tipo da Conta</p>
                  <select
                      name="select"
                      value={tipoConta}
                      onChange={(e) => {
                        setTipoConta(e.target.value);
                      }}
                    >
                      <option value="">Selecione um tipo</option>
                      <option value="CC">Conta Corrente</option>
                      <option value="CI">Conta Poupança</option>
                    </select>
                </div>

                <div>
                  <p>CPF ou CNPJ de quem vai receber</p>
                  <input
                    type="text"
                    placeholder="Digite o CPF ou CNPJ do destinatário"
                    value={cpf}
                    onChange={(e) => {
                      setCpf(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <p>Nome de quem irá receber</p>
                  <input
                    type="text"
                    placeholder="Digite o nome completo"
                    value={nome}
                    onChange={(e) => {
                      setNome(e.target.value);
                    }}
                  />
                </div>

                <div className="description">
                  <p style={{ marginBottom: "5px" }}>Descrição</p>
                  <textarea
                    type="text"
                    placeholder="Digite a descrição do pagamento"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-xl-6">
                {hasPreReceipt ? (
                  <div className="resumo-transferencia">
                    <div>
                      <h1>Dados de quem vai receber</h1>
                    </div>
                    <div>
                      <div>
                        <h2>{preReceit.name}</h2>
                      </div>

                      <div>
                        <p>CPJ/CNPJ</p>
                        <h4>{preReceit.cpf}</h4>
                      </div>

                      <div>
                        <p>Data</p>
                        <h4>{new Date(Date.now()).toLocaleDateString()}</h4>
                      </div>

                      <div>
                        <p>Tipo de transferencia</p>
                        <h4>TED</h4>
                      </div>

                      <div>
                        <p>Banco</p>
                        <h4>
                          {preReceit.banco} -{" "}
                          {
                            banco_codigo.find(
                              (item) => item.value === preReceit.banco
                            ).label
                          }
                        </h4>
                      </div>

                      <div className="agencia">
                        <div className="row">
                          <div className="col-xl-6">
                            <p>Agência</p>
                            <h4>{preReceit.agencia}</h4>
                          </div>

                          <div className="col-xl-8">
                            <p>Conta</p>
                            <h4>
                              {preReceit.conta.replace("_", "") +
                                "-" +
                                preReceit.digito}
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div>
                        <h3>Valor que será tranferido</h3>
                      </div>

                      <div>
                        <h4>R$ {preReceit.valor}</h4>
                      </div>
                    </div>

                    <div
                      style={
                        loader
                          ? {
                              display: "block",
                              margin: "auto",
                              marginTop: "20px",
                            }
                          : { display: "none" }
                      }
                    >
                      <BeatLoader
                        color={"var(--secondary-color)"}
                        loading={loader}
                        css={override}
                        size={20}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="resumo-transferencia">
                    <div>
                      <h1>Resumo da transferência</h1>
                    </div>
                    <div
                      style={
                        receipt ? { display: "block" } : { display: "none" }
                      }
                    >
                      <div>
                        <h2>{response.toName}</h2>
                      </div>

                      <div>
                        <p>CPJ/CNPJ</p>
                        <h4>{response.toTaxNumber}</h4>
                      </div>

                      <div>
                        <p>Data</p>
                        <h4>
                          {new Date(response.transferDate).toLocaleDateString()}
                        </h4>
                      </div>

                      <div>
                        <p>Tipo de transferencia</p>
                        <h4>TED</h4>
                      </div>

                      <div>
                        <p>Banco</p>
                        <h4>{response.toBank}</h4>
                      </div>

                      <div className="agencia">
                        <div className="row">
                          <div className="col-xl-6">
                            <p>Agência</p>
                            <h4>{response.toBankBranch}</h4>
                          </div>

                          <div className="col-xl-8">
                            <p>Conta</p>
                            <h4>
                              {response.toBankAccount +
                                "-" +
                                response.toBankAccountDigit}
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div>
                        <h3>Valor que será tranferido</h3>
                      </div>

                      <div>
                        <p>Valor</p>
                        <h4>R$ {response.value * -1}</h4>
                      </div>

                      <div>
                        <h3>Código de autenticação</h3>
                        <h4>
                          dsf213332-23243243d-324234234-42342344-432423434324334
                        </h4>
                      </div>
                    </div>

                    <div
                      style={
                        loader
                          ? {
                              display: "block",
                              margin: "auto",
                              marginTop: "20px",
                            }
                          : { display: "none" }
                      }
                    >
                      <BeatLoader
                        color={"var(--secondary-color)"}
                        loading={loader}
                        css={override}
                        size={20}
                      />
                    </div>
                  </div>
                )}

                <div className="botao-confirmar">
                  <button
                    style={
                      hasPreReceipt ? { display: "none" } : { display: "block" }
                    }
                    onClick={() => getInfos()}
                  >
                    Confirmar dados
                  </button>

                  <button
                    style={
                      hasPreReceipt ? { display: "block" } : { display: "none" }
                    }
                    onClick={() => confirm()}
                  >
                    Confirmar transferência
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <ReactModal
        isOpen={isOpenModal}
        className={"password-modal"}
        overlayClassName={"overlay-password-modal"}
      >
        <button
          className="modal-close-button"
          onClick={() => setIsOpenModal(false)}
        >
          <MdOutlineClose color="white" size={24} />
        </button>
        <label>Digite sua senha</label>
        <input
          type={"password"}
          value={transationalPassword}
          onChange={(e) => setTransationalPassword(e.target.value)}
        />
        <button
          className="modal-confirm-button"
          onClick={() => sendTransfers()}
        >
          Confirmar
        </button>
      </ReactModal>
    </>
  );
}
