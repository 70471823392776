import React from "react";

import "../pagamento-efetuado/Style.css"
import { getImage } from "../../../../../services/getAssets";

export default function(){
    return(
        <div className="pagamento-realizado">
            <div className="container">
                <div className="titulo">
                    <p>Pagamento Efetuado</p>
                </div>

                <div className="comprovante-pagamento">
                    <div className="centraliza">
                    <div className="imagem">
                        <img src={getImage('logo-preto.png')} alt="" />
                    </div>

                    <div>
                        <h1>Olá, Karina!
                        Sua fatura referente ao mês de Fevereiro com o valor de 
                        <strong> R$ 333,50</strong> foi paga com sucesso !!</h1>
                    </div>

                    <div className="vencimento">
                        <p><strong>Vencimento:</strong> 20 Fevereiro 2022</p>
                    </div>

                    <div className="limite">
                        <p><strong>Limite do Cartão:</strong> R$ 800,00</p>
                    </div>

                    <div className="pagamento">
                        <p><strong>Pagamento realizado:</strong> R$ 333,50</p>
                    </div>

                    <div className="botoes">
                        <div className="row">
                            <div className="email col-xl-6">
                                <button>Enviar para o Email</button>
                            </div>

                            <div className="pdf col-xl-6">
                                <button>Gerar PDF</button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}