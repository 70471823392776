import { toast } from "react-toastify";
import api from "./api";
import moment from "moment";

export const updateEntrysFromFitBank = async (taxNumber, accountId) => {
  let response = await api.post(`/entrys/updateFromFitBank`, {
    taxNumber,
    accountId,
  });
  return response.data.ok;
};

export const updateEntrysFromFiducia = async (token, accountId) => {
  let response = await api.post(`/entrys/updateFromFiducia`, {
    token,
    accountId,
  });
  return response.data.ok;
};

export const getEntryDetails = async (type, documentNumber) => {
  console.log("type ", type);
  let taxNumber = localStorage.getItem("taxNumber");
  let bank = localStorage.getItem("bank");
  let bankBranch = localStorage.getItem("bankBranch");
  let bankAccount = localStorage.getItem("bankAccount");
  let bankAccountDigit = localStorage.getItem("bankAccountDigit");

  try {
    let response = await api.post("/entrys/getEntryDetails", {
      type,
      documentNumber,
      taxNumber,
      bank,
      bankBranch,
      bankAccount,
      bankAccountDigit,
    });
    let payload = {};

    if (type === "PreDebitPixOut") {
      let info = response.data.data.Infos;
      info.PaymentDate = info.PaymentDate.split(" ")[0]
        .split("/")
        .reverse()
        .join("-");
      payload = {
        value: info.TotalValue,
        name: info.ToName,
        toBank: info.ToBankCode,
        toBankBranch: info.ToBankBranch,
        toBankAccount: info.ToBankAccount,
        toBankAccountDigit: info.ToBankAccountDigit,
        taxNumber: info.ToTaxNumber,
        documentNumber: info.DocumentNumber,
        date: info.PaymentDate,
        bank,
        bankBranch,
        bankAccount,
        bankAccountDigit,
      };
    } else if (type === "PreDischargeAccount") {
      let info = JSON.parse(response.data.data.Transferencia);
      payload = {
        value: info.PrincipalValue,
        name: info.ToName,
        toBank: info.BankNumber,
        toBankBranch: info.BankAgency,
        toBankAccount: info.BankAccount,
        toBankAccountDigit: info.BankAccountDigit,
        taxNumber: info.ToTaxNumber,
        documentNumber: info.DocumentNumber,
        date: info.PaymentDate,
        bank,
        bankBranch,
        bankAccount,
        bankAccountDigit,
      };
    } else if (type === "PreDebitBoletoOut") {
      let info = JSON.parse(response.data.data.Titulo);
      payload = {
        value: info.TotalValue,
        name: "",
        toBank: info.SenderCodeBank,
        toBankBranch: info.SenderBranch,
        toBankAccount: info.SenderAccount,
        toBankAccountDigit: info.SenderAccountDigit,
        taxNumber: "",
        documentNumber: info.DocumentNumber,
        date: moment(info.PaymentDate).format("YYYY-MM-DD HH:mm:ss"),
        bank,
        bankBranch,
        bankAccount,
        bankAccountDigit,
        barcode: info.Barcode,
      };
    } else if (type === "CreditPixIn") {
      let info = response.data.data.Infos;
      payload = {
        value: info.TotalValue,
        name: info.FromName,
        bank: info.FromBankCode,
        bankBranch: info.FromBankBranch,
        bankAccount: info.FromBankAccount,
        bankAccountDigit: info.FromBankAccountDigit,
        taxNumber: info.FromTaxNumber,
        documentNumber: documentNumber,
        date: moment(info.TransferDate).format("YYYY-MM-DD HH:mm:ss"),
        toBank: bank,
        toName: "",
        toBankBranch: bankBranch,
        toBankAccount: bankAccount,
        toBankAccountDigit: bankAccountDigit,
      };
    } else if (type === "CreditTedIn") {
      let info = JSON.parse(response.data.data.Message);
      payload = {
        value: info.PrincipalValue,
        name: "",
        bank: info.FromBank,
        bankBranch: info.FromBankBranch,
        bankAccount: info.FromBankAccount,
        bankAccountDigit: info.FromBankAccountDigit,
        taxNumber: info.FromTaxNumber,
        documentNumber: documentNumber,
        date: moment(info.TransferDate).format("YYYY-MM-DD HH:mm:ss"),
        toBank: bank,
        toName: "",
        toBankBranch: bankBranch,
        toBankAccount: bankAccount,
        toBankAccountDigit: bankAccountDigit,
      };
    } else if (type === "RateTedIn" || type === "RatePixIn") {
      payload.message = response.data.data.message;
    }
    return payload;
  } catch (err) {
    console.log("erro", err);
  }
};

export const getEntryDetailsFiducia = async (type, documentNumber) => {
  let bank = localStorage.getItem("bank");
  let bankBranch = localStorage.getItem("bankBranch");
  let bankAccount = localStorage.getItem("bankAccount");
  let bankAccountDigit = localStorage.getItem("bankAccountDigit");

  const token = localStorage.getItem("fiduciaToken");
  let response = await api.post("/entrys/getEntryDetailsFiducia", {
    type,
    documentNumber,
    token,
  });
  console.log("response ", response);
  let entry = response.data.data[0];
  let payload = {
    value: entry.valor_lancamento,
    name: entry.nome_clienteCredtada,
    toBank: entry.banco_credito ? entry.banco_credito : bank,
    toBankBranch: entry.agCredtda,
    toBankAccount: entry.ctCredtda.slice(0, -1),
    toBankAccountDigit: entry.ctCredtda.slice(-1),
    taxNumber: entry.cnpj_cpf_clienteCredtada,
    documentNumber: entry.numCtrlIF,
    date: entry.created_at,
    bank,
    bankBranch,
    bankAccount,
    bankAccountDigit,
  };

  return payload;
};

export const listEntrys = async (filters) => {
  const accountId = localStorage.getItem("accountId");
  const taxNumber = localStorage.getItem("taxNumber");
  const fiduciaToken = localStorage.getItem("fiduciaToken");
  const bank = localStorage.getItem("bank");

  const payload = {
    accountId,
    taxNumber,
  };

  if (bank !== "450") {
    await updateEntrysFromFiducia(fiduciaToken, accountId);
  } else {
    await updateEntrysFromFitBank(taxNumber, accountId);
  }
  if (filters) {
    payload.type = filters.type;
    payload.initialDate = filters.initialDate;
    payload.finalDate = filters.finalDate;
  }

  const filterResponse = await api.post("/entrys/list", payload);
  if (filterResponse.data.ok) {
    let sortedData = filterResponse.data.data.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    return { data: sortedData, statusCode: 200 };
  } else {
    toast.error("Não foi possivel carregar os Extratos");
    return {
      statusCode: 200,
      message: "Não existem extratos para essa conta!",
    };
  }
};
