import React, { useEffect, useState } from "react";

import "../../components/extrato/Style.css"
import DescExtrato from "../../components/extrato/desc-extrato/DescExtrato"

import { ToastContainer } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import { entryTypes } from "../../services/enums";
import ExtratoRow from "./extrato-row/ExtratoRow";
import { getEntryDetails, getEntryDetailsFiducia, listEntrys } from "../../services/entrys.service";
import { MdAutorenew, MdOutlineFilterAlt } from "react-icons/md";
import moment from "moment";

export default function () {
  const [entries, setEntries] = useState()
  const [descExtrato, setDescExtrato] = useState()
  const [entryMessage, setEntryMessage] = useState('')
  const [loader, setLoader] = useState(false)
  const [isFilterAreaOpen, setIsFilterAreaOpen] = useState(false)
  const [filterOptions, setFilterOptions] = useState({
    type: "all",
    initialDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    finalDate: moment().format('YYYY-MM-DD')
  })

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
    margin-top: 3em;
  `;

  async function getEntry() {
    setLoader(true)
    try{
      let extratos = await listEntrys()
      
      if(extratos.data.length){
        setEntries(extratos.data)
      }else{
        setEntries()
        setEntryMessage('Não existem extratos para essa conta!')
      }
      setLoader(false)
    } catch (err) {
      console.log("err ", err);
      toast.error('Não foi possivel carregar os Extratos')
      setEntryMessage('Não foi possivel carregar os extratos para essa conta!')
      setLoader(false)
    }
  }
  const handleOnClick = async (item) => {

      let bank = localStorage.getItem('bank')

      if(bank === '450'){
        let response = await getEntryDetails(item.type, item.documentNumber)
        let typeResult = entryTypes.filter((type)=> type.key === item.type)
        response.type = typeResult[0].displayValue
        response.description = item.description
        setDescExtrato( response )
      } else {
        let response = await getEntryDetailsFiducia(item.subType, item.documentNumber)
        let typeResult = entryTypes.filter((type)=> type.key === item.type)
        response.type = typeResult[0].displayValue
        response.description = item.description
        setDescExtrato( response )
      }
  }

  const toggleFilterArea = () =>{
    setIsFilterAreaOpen(!isFilterAreaOpen)
    setDescExtrato('')
  }

  const handleFilterOptions = (e) => {
    setFilterOptions({
      ...filterOptions,
      [e.target.id] : e.target.value 
    })
  }

  const setFilter = async () =>{
    const response = await listEntrys(filterOptions)
    setEntries(response)
  }

  const toggleReloadEntryes = async () => {
    if(entries) {
      setEntries();
      await getEntry();
    } else {
      setEntryMessage('Não existem extratos para essa conta!');
    }
  }


  useEffect(() => {
    getEntry()

  }, []);

  return (
    <div className="pagina-extrato">
      <div className="container">
        <div className="extrato row">
          <div className="col-xl-6">
            <p>Extrato</p>
          </div>
        </div>

        <div className="fundo-lancamento">
          <div className="row">
            <div className="scroll col-xl-6">
              <div className="lancamento">
                <div className=" row">

                  <div className="lancamento-title">
                    <div>
                      <h1>Últimos Lançamentos</h1>
                    </div>
                    <div className="lancamento-filter-icon">
                      <button className="filter-button" onClick={async() => await toggleReloadEntryes()}>
                        <MdAutorenew size={28}/>
                      </button>
                      <button className="filter-button" onClick={()=>toggleFilterArea()}>
                        <MdOutlineFilterAlt size={28}/>
                      </button>
                    </div>
                  </div>
                    { entries ?
                      entries.map((item)=> 
                        item.type != 'FinalBalance' & item.type != "InitialBalance" ? 
                        <ExtratoRow data={item} handleOnClick={handleOnClick} key={item.id}/> : ''
                      ) 
                      :
                      <div className="entryMessage">
                        {entryMessage}
                      </div>
                    }
                    <div className="loader-div">
                      <BeatLoader color={"var(--secondary-color)"} loading={loader} css={override} size={20} />
                    </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
            {
                isFilterAreaOpen ? 
                ( 
                  <div className="filter-area">
                    <div className="filter-area-header">
                      <h1>Aplicar Filtro</h1>
                    </div>
                    <div className="filter-area-body">
                      <div className="filter-inputs">
                        <label>Filtrar por:</label>
                        <select id="type" value={filterOptions.type} onChange={(e) => handleFilterOptions(e)}>
                          <option value={"All"}>Todos</option>
                          <option value={"CreditPixIn"}>Pix recebido</option>
                          <option value={"PreDebitPixOut"}>Pix enviado</option>
                          <option value={'CreditTedIn'}>TED recebido</option>
                          <option value={'PreDischargeAccount'}>TED enviado</option>
                          <option value={'PreDebitBoletoOut'}>Pagamento de boleto</option>
                          <option value={'PurchaseWithBoleto'}>Compra com boleto</option>
                        </select>
                      </div>
                      <div className="filter-inputs">
                        <label>Data Inicial:</label>
                        <input type="date" id="initialDate" value={filterOptions.initialDate} onChange={(e) => handleFilterOptions(e)}/>
                      </div>
                      <div className="filter-inputs">
                        <label>Data Final:</label>
                        <input type="date" id="finalDate" value={filterOptions.finalDate} onChange={(e) => handleFilterOptions(e)}/>
                      </div>
                    </div>
                    <div className="filter-area-footer">
                      <button onClick={setFilter}>Filtrar</button>
                    </div>
                  </div>
                )
                :
                (
                  descExtrato ? 
                    <DescExtrato data={descExtrato} />
                  : ""
                )
              }
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
