export const pixTypes = [
  {
    key: 1,
    value: "CNPJ",
  },
  {
    key: 2,
    value: "Email",
  },
  {
    key: 3,
    value: "Telefone",
  },
  {
    key: 4,
    value: "Chave Aleatória",
  },
];

export const entryTypes = [
  {
    key: "FinalBalance",
    displayValue: "Saldo Final",
  },
  {
    key: "InitialBalance",
    displayValue: "Saldo Inicial",
  },
  {
    key: "CreditPixIn",
    displayValue: "Pix Recebido",
  },
  {
    key: "RatePixIn",
    displayValue: "Taxa de Recebimento de Pix",
  },
  {
    key: "PreDebitPixOut",
    displayValue: "Pix Enviado",
  },
  {
    key: "CreditRefundPixOut",
    displayValue: "Devolução Pix",
  },
  {
    key: "Transfer",
    displayValue: "Transferencia por TED",
  },
  {
    key: "PreDischargeAccount",
    displayValue: "Transferência",
  },
  {
    key: "PreDebitBoletoOut",
    displayValue: "Pagamento de Boleto",
  },
  {
    key: "CreditTedIn",
    displayValue: "Recebimento por TED",
  },
  {
    key: "RateTedIn",
    displayValue: "Taxa de Recebimento de TED",
  },
  {
    key: "PreRateByPixOut",
    displayValue: "taxa de Envio de Pix",
  },
  {
    key: "C",
    displayValue: "Credito fiducia",
  },
  {
    key: "D",
    displayValue: "Debito fiducia",
  },
  {
    key: "Pix",
    displayValue: "Pix Enviado",
  },
  {
    key: "TARIFA_PIX",
    displayValue: "Taxa de Envio de Pix",
  },
  {
    key: "STR0008",
    displayValue: "Transferencia por TED",
  },
  {
    key: "TAXA_TED",
    displayValue: "Taxa de Envio de TED",
  },
  {
    key: "Boleto",
    displayValue: "Pagamento de Boleto",
  },
  {
    key: "RateInternalDischargeAccount",
    displayValue: "Taxa de Transferencia",
  },
  {
    key: "RateByBoleto",
    displayValue: "Taxa de recebimento de boleto",
  },
  {
    key: "CreditBoleto",
    displayValue: "Confirmação de pgto de boleto",
  },
  {
    key: "Taxa de recebimento de boleto",
    displayValue: "Taxa de recebimento de boleto",
  },
  {
    key: "Confirmação de pgto de boleto",
    displayValue: "Confirmação de pgto de boleto",
  },
  {
    key: "Pré-taxa para transferencia",
    displayValue: "Pré-taxa para transferencia",
  },
];
