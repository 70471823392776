import React from "react";

import MenuLateral from "../../../components/menu-lateral/MenuLateral";
import Header from "../../../components/header/Header";
import BoasVindas from "../../../components/boas-vindas/BoasVindas";
import SemCartaoCredito from "../../../components/acesso-rapido/cartoes/sem-cartao-credito/index"

export default function(){
    return(
        <div>
            <MenuLateral/>
            <Header/>
            <div>
                <BoasVindas/>
                <SemCartaoCredito/>
            </div>
        </div>
    )
}