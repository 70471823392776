import React from "react";

import "../pagina-boleto/Style.css";

import Header from "../../components/header/Header"
import MenuLateral from "../../components/menu-lateral/MenuLateral"
import BoasVindas from "../../components/boas-vindas/BoasVindas"
import Boleto from "../../components/acesso-rapido/boleto/Boleto"

export default function () {
  return (
    <div>
      <Header />
      <MenuLateral />
      <div className="pagina-boleto">
        <BoasVindas />
        <Boleto />
      </div>
    </div>
  );
}
