import React, { useContext, useState } from "react";

import "./Style.css"

import { Link, useNavigate } from "react-router-dom";
import { pixContext } from "../../../../services/Context";
import ReactInputMask from "react-input-mask";
import { toast, ToastContainer } from "react-toastify";

export default function () {
    const {keyValue, setKeyValue, keyType, setKeyType} = useContext(pixContext)
    const [disabledInput, setDisabledInput] = useState(false)
    const [placeHolderInput, setPlaceHolderInput] = useState('Digite a chave Pix')
    const [inputMask, setInputMask] = useState('')

    let cnpj = localStorage.getItem('taxNumber')
    const navigate = useNavigate()

    const handleSelectOnChange = (value) => {
        if(value == 0){
            setKeyValue('')
            setInputMask('')
            setDisabledInput(false)
        }
        if(value == 1){
            setKeyValue(cnpj)
            setDisabledInput(true)
        }
        else if(value == 2 ){
            setKeyValue('')
            setInputMask('')
            setDisabledInput(false)
        }
        else if(value == 3 ){
            setKeyValue('')
            setInputMask('(99)99999-9999')
            setDisabledInput(false)
        }
        else if(value == 4){
            setKeyValue('')
            setDisabledInput(true)
            setPlaceHolderInput('Chave aleatória será gerada automaticamente')
        }
        setKeyType(value)
    }

    const handleOnClick = () => {
        if(keyType != 4){
            if(keyValue == ""){
                toast.error('Valor é obrigatório')
                return false
            }
        }
        if(keyType == ""){
            toast.error('Tipo da chave é obrigatório')
            return false
        }
        navigate('/confirmar-chave-pix')
    }

    return (
        
            <div className="cadastro-chave-pix">
                <div className="container">
                <div className='titulo row'>
                    <div className='col-xl-8'>
                        <p>Cadastrar Nova Chave Pix</p>
                    </div>
                    <div className="col-xl-4 d-flex gap-2 justify-content-end">
                        <div className="">
                            <Link to={"/pagina-pix"}>
                                <button style={{ backgroundColor: "var(--secondary-color)" }}>Nova transferencia</button>
                            </Link>
                        </div>

                        <div className="">
                            <Link to={"/chave-pix"}>
                                <button style={{ backgroundColor: "var(--secondary-color)" }}>Minhas chaves</button>
                            </Link>
                        </div>
                    </div>
                </div>

                    <div className="cadastro-chave">
                        <div>
                            <h1>Cadastre uma nova Chave</h1>
                        </div>

                        <div>
                            <h2>Selecione o tipo de chave e digite sua nova chave Pix</h2>
                        </div>

                        <div>
                            <h3>Tipo de Chave</h3>
                            <div className='seleciona'>
                                <select name="select" onChange={(e) => handleSelectOnChange(e.target.value)}>
                                    <option value="">Selecione um tipo</option>
                                    <option value="1">CNPJ</option>
                                    <option value="4">Chave aleatória</option>
                                </select>
                            </div>
                        </div>

                        <div>
                            <h3>Chave Pix</h3>
                            <ReactInputMask mask={inputMask} 
                                            disabled={disabledInput} 
                                            value={keyValue} 
                                            placeholder={placeHolderInput} 
                                            onChange={(e) => setKeyValue(e.target.value)}
                            >
                            </ReactInputMask>
                        </div>

                        <div>
                            <button onClick={() => handleOnClick()}>Avançar</button>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </div>
    )
}