import React from "react";

import "../pagina-principal/Style.css";

import Header from "../../components/header/Header";
import MenuLateral from "../../components/menu-lateral/MenuLateral";
import Extrato from "../../components/extrato/Extrato";
import BoasVindas from "../../components/boas-vindas/BoasVindas";

export default function () {
  return (
    <>
      <Header />
      <MenuLateral />

      <div className="pagina-principal">
        <BoasVindas />
        <Extrato />
      </div>
    </>
  );
}
