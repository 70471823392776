import React from "react";

import "../ver-senha/Style.css"

import {FiEyeOff} from "react-icons/fi"

export default function () {
    return (
        <div>
            <div className="ver-senha">
                <div className="container">
                    <div>
                        <p>Ver senha Cartão</p>
                    </div>

                    <div className="senha">
                        <div>
                            <h1>Sua Senha do Cartão</h1>
                        </div>

                        <div className="mostrar-senha">
                            <div className="row">
                                <div className="col-xl-6">
                                    <p>1234</p>
                                </div>

                                <div className="icone col-xl-6">
                                    <FiEyeOff/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}