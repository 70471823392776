import React, { useState } from "react";
import "../../acesso-rapido/pix/Style.css";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import IntlCurrencyInput from "react-intl-currency-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import {
  getPixDetailsFiducia,
  getPixInfo,
  sendPixFiducia,
  sendPixFitBank,
} from "../../../services/pix.service";
import ReactModal from "react-modal";
import { MdOutlineClose } from "react-icons/md";

export default function () {
  const [valor, setValor] = useState(0);
  const [chave, setChave] = useState("");
  const [tipoChave, setTipoChave] = useState("");
  const [description, setDescription] = useState("");
  const [response, setResponse] = useState({});
  const [transationalPassword, setTransationalPassword] = useState("")

  const [receipt, setReceipt] = useState(false);
  const [preReceit, setPreReceipt] = useState();
  const [hasPreReceipt, setHasPreReceipt] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false)

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();

    setValor(value); // value without mask (ex: 1234.56)
  };

  const confirm = () =>{
    let bank = localStorage.getItem('bank')
    if( bank != "450"){
      setIsOpenModal(true)
    }else{
      sendpix()
    }
  }

  async function getInfos() {
    setLoader(true)
    if (valor == "" || chave == "" || tipoChave == "") {
      toast.error("Preencha todos os campos!");
      setLoader(false);
      return;
    }
    try{

      const bank = localStorage.getItem("bank");
      if (bank === "450") {
        let taxNumber = localStorage.getItem("taxNumber");
        let response = await getPixInfo(chave, tipoChave, taxNumber);
        let preReceipt = response.data.data;
        preReceipt.value = valor;
        preReceipt.description = description;
        setPreReceipt(preReceipt);
        setHasPreReceipt(true);
        setLoader(false);
      } else {
        let getInfoResponse = await getPixDetailsFiducia(chave);
        console.log("getInfoResponse ", getInfoResponse);

        if(getInfoResponse.response?.status == 401){
          toast.error('Sessão expirada! Logue novamente');
          return;
        } else if( getInfoResponse.response?.status === 400) {
          toast.error(getInfoResponse.response.data.message);
          setLoader(false);
          return;
        }

        let preReceipt = getInfoResponse;
        preReceipt.value = valor;
        preReceipt.description = description;
        setPreReceipt(preReceipt);
        setHasPreReceipt(true);
        setLoader(false);
      }
    }catch(err){
      console.log("err ", err.response.data.message);
      toast.error(err.response.data.message)
      setLoader(false);
    }
  }

  async function sendpix() {
    let bank = localStorage.getItem("bank");

    if (!transationalPassword && bank !== "450") {
      toast.error("Digite a senha");
      return;
    }
    setIsOpenModal(false);
    setLoader(true);

    if (!preReceit) {
      toast.error("Preencha todos os campos!");
      setLoader(false);
      return;
    }

    const body = {
      value: preReceit.value,
      keyType: preReceit.PixKeyType,
      key: preReceit.PixKeyValue,
      fromTaxNumber: localStorage.getItem("taxNumber"),
      toTaxNumber: preReceit.ReceiverTaxNumber,
      description: preReceit.description,
    };

    setReceipt("");
    setHasPreReceipt(false);

    const obj = {
      username: localStorage.getItem("taxNumber"),
      password: localStorage.getItem("identifier"),
      mac: localStorage.getItem("deviceId"),
      login: localStorage.getItem("login"),
    };

    await api.post("/login/token", obj).then(async function (result) {
      localStorage.setItem("token", result.data.token);

      const bank = localStorage.getItem("bank");
      if (bank === "450") {
        let pixResponse = await sendPixFitBank(body);

        if (pixResponse) {
          let response = pixResponse.data;
          toast.success("Pix Enviado com sucesso!");
          setResponse(response);
          console.log("response ", response);
          setReceipt(true);
        }
        setLoader(false);
        setValor(0);
        setTipoChave("");
        setDescription("");
      } else {
        let payload = {
          key_value: body.key,
          value: body.value,
          description: body.description,
          transational_password: transationalPassword,
        };

        try{
          let pixResponse = await sendPixFiducia(payload);
  
          if (pixResponse) {
            toast.success("Pix Enviado com sucesso!");
            setResponse(pixResponse);
            console.log(pixResponse);
            setReceipt(true);
          }

          setLoader(false);
          setValor(0);
          setTipoChave("");
          setDescription("");
          
        } catch(err){
          console.log("err ", err);
          toast.error(err.response.data.message);
          setLoader(false);
          return;
        }

      }
    });
  }

  return (
    <>
      <div className="transferencia-pix">
        <div className="container">
          <div className="pix row">
            <div className="col-xl-8">
              <p>Pix</p>
            </div>
            <div className="col-xl-4 d-flex gap-2 justify-content-end">
              <div className="">
                <Link to={"/chave-pix"}>
                  <button style={{ backgroundColor: "var(--secondary-color)" }}>
                    Minhas Chaves
                  </button>
                </Link>
              </div>
              <div className="">
                <Link to={"/nova-chave-pix"}>
                  <button style={{ backgroundColor: "var(--secondary-color)" }}>
                    Cadastrar Chave
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="transferencia">
            <div className="row">
              <div className="scroll col-xl-6">
                <div>
                  <h1>Nova transferência</h1>
                </div>

                <div>
                  <h2>Qual valor você deseja transferir?</h2>
                </div>

                <div>
                  <p style={{ marginBottom: "5px" }}>Valor</p>
                  <IntlCurrencyInput
                    currency="BRL"
                    config={currencyConfig}
                    onChange={handleChange}
                    placeholder="Digite o valor a ser transferido"
                    value={valor}
                    // onChange={e => { setValor(e.target.value) }}
                  />
                </div>

                <div>
                  <h2>Para quem você quer transferir</h2>
                </div>

                <div style={{ marginTop: "15px" }}>
                  <p style={{ marginBottom: "5px" }}>Tipo de chave</p>
                  <div className="seleciona">
                    <select
                      name="select"
                      value={tipoChave}
                      onChange={(e) => {
                        setTipoChave(e.target.value);
                      }}
                    >
                      <option value="">Selecione um tipo</option>
                      <option value="0">CPF</option>
                      <option value="1">CNPJ</option>
                      <option value="2">E-mail</option>
                      <option value="3">Número de telefone celular</option>
                      <option value="4">Chave aleatória</option>
                    </select>
                  </div>
                </div>

                <div className="chave">
                  <p style={{ marginBottom: "5px" }}>Chave</p>
                  <input
                    type="text"
                    placeholder="Digite a chave"
                    value={chave}
                    onChange={(e) => {
                      setChave(e.target.value);
                    }}
                  />
                </div>
                <div style={{ marginTop: "15px" }} className="description">
                  <p style={{ marginBottom: "5px" }}>Descrição</p>
                  <textarea
                    type="text"
                    placeholder="Digite a descrição do pagamento"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-xl-6">
                {hasPreReceipt ? (
                  <div className="resumo-transferencia">
                    <div>
                      <h1>Dados de quem vai receber</h1>
                    </div>

                    <div
                      style={
                        hasPreReceipt
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div>
                        <h2>{preReceit.ReceiverName}</h2>
                      </div>

                      <div>
                        <p>CPJ/CNPJ</p>
                        <h4>{preReceit.ReceiverTaxNumber}</h4>
                      </div>

                      <div>
                        <p>Chave</p>
                        <h4>{preReceit.PixKeyValue}</h4>
                      </div>

                      <div>
                        <p>Banco</p>
                        <h4>{preReceit.ReceiverBankName}</h4>
                      </div>

                      <div className="agencia">
                        <div className="row">
                          <div className="col-xl-6">
                            <p>Agência</p>
                            <h4>{preReceit.ReceiverBankBranch}</h4>
                          </div>

                          <div className="col-xl-6">
                            <p>Conta</p>
                            <h4>
                              {preReceit.ReceiverBankAccount +
                                "-" +
                                preReceit.ReceiverBankAccountDigit}
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div>
                        <p>Valor</p>
                        <h4>R$ {preReceit.value}</h4>
                      </div>

                      <div>
                        <h3>Quando será transferido</h3>
                        <h4>Agora</h4>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="resumo-transferencia">
                    <div>
                      <h1>Resumo da transferência</h1>
                    </div>

                    <div
                      style={
                        receipt ? { display: "block" } : { display: "none" }
                      }
                    >
                      <div>
                        <h2>{response.toName}</h2>
                      </div>

                      <div>
                        <p>CPJ/CNPJ</p>
                        <h4>{response.toTaxNumber}</h4>
                      </div>

                      <div>
                        <p>Chave</p>
                        <h4>{chave}</h4>
                      </div>

                      <div>
                        <p>Banco</p>
                        <h4>{response.toBank}</h4>
                      </div>

                      <div className="agencia">
                        <div className="row">
                          <div className="col-xl-6">
                            <p>Agência</p>
                            <h4>{response.toBankBranch}</h4>
                          </div>

                          <div className="col-xl-6">
                            <p>Conta</p>
                            <h4>
                              {response.toBankAccount +
                                "-" +
                                response.toBankAccountDigit}
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div>
                        <p>Valor</p>
                        <h4>R$ {response.value}</h4>
                      </div>

                      <div>
                        <h3>Quando será transferido</h3>
                        <h4>Agora</h4>
                      </div>
                    </div>

                    <div
                      style={
                        loader
                          ? {
                              display: "block",
                              margin: "auto",
                              marginTop: "20px",
                            }
                          : { display: "none" }
                      }
                    >
                      <BeatLoader
                        color={"var(--secondary-color)"}
                        loading={loader}
                        css={override}
                        size={20}
                      />
                    </div>
                  </div>
                )}

                <div className="botao-confirmar">
                  <button
                    style={
                      hasPreReceipt ? { display: "none" } : { display: "block" }
                    }
                    onClick={() => getInfos()}
                  >
                    Confirmar dados
                  </button>

                  <button
                    style={
                      hasPreReceipt ? { display: "block" } : { display: "none" }
                    }
                    onClick={() => confirm()}
                  >
                    Confirmar transferência
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <ReactModal isOpen={isOpenModal} className={'password-modal'} overlayClassName={'overlay-password-modal'}>
          <button className="modal-close-button" onClick={()=>setIsOpenModal(false)}><MdOutlineClose color="white" size={24}/></button>
          <label>Digite sua senha</label>
          <input type={'password'} value={transationalPassword} onChange={(e)=>setTransationalPassword(e.target.value)}/>
          <button className="modal-confirm-button" onClick={() => sendpix()}>Confirmar</button>
      </ReactModal>
    </>
  );
}
