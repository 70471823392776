import React from "react";

import MenuLateral from "../../../../components/menu-lateral/MenuLateral";
import Header from "../../../../components/header/Header";
import BoasVindas from "../../../../components/boas-vindas/BoasVindas";
import CartaoDebito from "../../../../components/acesso-rapido/cartoes/cartao-debito/opcoes-cartao-debito/index"

export default function(){
    return(
        <div>
            <MenuLateral/>
            <Header/>
            <div>
                <BoasVindas/>
                <CartaoDebito/>
            </div>
        </div>
    )
}