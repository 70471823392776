import React from "react";

import './Style.css';
import { getImage } from "../../../../services/getAssets";

export default function () {
    return (
        <div className="cards">
            <div className="container">
                <p>Cartões</p>
                <div className="body" >
                    <div>
                        <h1>Meus Cartões</h1>
                        <h2>Selecione o cartão para mais opções</h2>
                    </div>
                    <div className="card-debit">

                        <p>Cartão de debito</p>

                        <img src={getImage('cartao.png')} alt="Cartão de debito" />


                    </div>

                    <div />
                    <div className="card-debit">
                        <p>Cartão de Crédito</p>
                        <div className="img-card col-xl-2" >
                            <img src={getImage('card-soon.png')} alt="Cartão de crédito em breve" />

                        </div>
                        <div />
                    </div>
                </div>
            </div>
        </div>
    )
}