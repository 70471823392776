import React, { useContext, useEffect, useState } from "react";
import api, { apiUrl } from "../../services/api";
import "../../components/menu-lateral/Style.css";

import { Link, useNavigate } from "react-router-dom";

import Acesso from "../acesso-rapido/AcessoRapido";

import { BsEyeSlash, BsEye } from "react-icons/bs";
import { FiChevronRight } from "react-icons/fi";
import { toast, ToastContainer } from "react-toastify";
import { getImage } from "../../services/getAssets";
import { getBalanceFromFitBank } from "../../services/balance.service";

export default function () {
  const navigate = useNavigate()
  const [balance, setBalance] = useState('0')
  const [hidden, setHidden] = useState(false)

  const getBalance = async () => {

    let fiduciaToken = localStorage.getItem('fiduciaToken')
    
    const balan = await api.get(`/account/show/${localStorage.getItem('accountId')}/${fiduciaToken}`)
    .catch(err => {
      toast.error(err.response.data.message)
      setTimeout(() => {
        localStorage.clear()
        navigate('/')
      }, 2000);
      return
    })
    let balance = (await getBalanceFromFitBank(balan.data.data.balance.id, fiduciaToken)).toFixed(2).toString()
    setBalance(balance);
  }

  useEffect(() => {
    getBalance()
  }, [])

  return (
    <div className={`menu`}>
      <div className="container">
        <Link to={"/pagina-principal"}>
          <div className="d-flex mt-3">
            <img src={getImage('logo-preto.png')} alt="" />
          </div>
        </Link>


        <div className="saldo">
          <div className="row">
            <div className="col-xl-6">
              <p>Saldo em conta</p>
              <p style={hidden ? { 'display': 'block' } : { 'display': 'none' }} id="dinheiro">{'R$ ' + balance.replace('.', ',')}</p>
              <p style={hidden ? { 'display': 'none' } : { 'display': 'block' }} id="dinheiro">-</p>
            </div>

            <div style={hidden ? { 'display': 'block' } : { 'display': 'none' }} className="BsEyeSlash col-xl-3">
              <button onClick={() => { setHidden(prev => !prev) }} style={{ 'background': 'transparent', 'border': 'none' }}><BsEyeSlash color="#fff" /></button>
            </div>
            <div style={hidden ? { 'display': 'none' } : { 'display': 'block' }} className="BsEyeSlash col-xl-3">
              <button onClick={() => { setHidden(prev => !prev) }} style={{ 'background': 'transparent', 'border': 'none' }}><BsEye color="#fff" /></button>
            </div>

            <div className="FiChevronRight col-xl-3">
              <Link to={"/pagina-principal"}>
                <FiChevronRight color="#fff" />
              </Link>
            </div>
          </div>
        </div>


        {/* <div className="meus-cartoes">
          <h1>MEUS CARTÕES</h1>
        </div>

        <div className="cartao">
          <div className="row">
            <div className="col-xl-5">
              <img src={getImage('cartoes-home.png')} alt="" />
            </div>

            <div className="col-xl-6">
              <p>Que tal um cartão sem anuidade e cheio de benefícios?</p>
            </div>
          </div>
        </div> */}
        <Acesso />
      </div>
      <ToastContainer />
    </div>
  );
}
