import React from "react";

import Header from "../../../components/header/Header";
import MenuLateral from "../../../components/menu-lateral/MenuLateral";
import BoasVindas from "../../../components/boas-vindas/BoasVindas";
import ConfirmarCadatroPix from "../../../components/acesso-rapido/pix/confirmar-cadatro-pix/ConfirmarCadatroPix";


export default function(){
    return(
        <div>
            <Header/>
            <MenuLateral/>
            <div>
                <BoasVindas/>
                <ConfirmarCadatroPix/>
            </div>
        </div>
    )
}