import React from "react";

import "../minhas-chaves/Style.css"
import { Link } from "react-router-dom";

import { useEffect } from "react";
import { getAll } from "../../../../services/pix.service";
import PixRow from "../pix-row/pixRow";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';

export default function () {
    const [rows, setRows] = useState([])
    const [loader, setLoader] = useState(false)

    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
      margin-top: 3em;
    `;
    useEffect( ()=>{
        const getKeys = async () => {
            setLoader(true)
            try {
              const response = await getAll();
              setRows(response);
            } catch (error) {
              toast.error('Erro ao recuperar as chaves, tente novamente');
            }
            setLoader(false)
          };
        getKeys()
    },[])
    return (
        <div className="minhas-chaves-pix">
            <div className="container">
                <div className='titulo row'>
                    <div className='col-xl-8'>
                        <p>Minhas Chaves Pix</p>
                    </div>
                    <div className="col-xl-4 d-flex gap-2 justify-content-end">
                        <div className="">
                            <Link to={"/pagina-pix"}>
                                <button style={{ backgroundColor: "var(--secondary-color)" }}>Nova transferencia</button>
                            </Link>
                        </div>

                        <div className="">
                            <Link to={"/nova-chave-pix"}>
                                <button style={{ backgroundColor: "var(--secondary-color)" }}>Cadastrar Chave</button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="chaves-pix">
                    <div>
                        <h1>Suas Chaves Pix</h1>
                    </div>

                    <div className="registra-chave">
                        <div className="row">

                            <div className="col-xl-6">
                                <h2>Gerencie suas chaves</h2>
                            </div>
                        </div>
                    </div>

                    <div className="chave-cadastrada">
                        <p>Chaves já Cadastradas</p>
                    </div>
                    <BeatLoader color={"var(--secondary-color)"} loading={loader} css={override} size={20}/>
                    { rows.length > 0 ?
                        rows.map((data)=> {
                            return <PixRow data={data} key={data.id}></PixRow>} )
                        :
                        ""
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}