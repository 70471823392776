import React from "react";

import "../cartao-senha/Style.css"

import { Link } from "react-router-dom";

export default function () {
    return (
        <div className="senha">
            <div className="container">
                <div>
                    <p>Ver senha Cartão</p>
                </div>

                <div className="codigo-senha">
                    <div>
                        <h1>Digite o codigo enviado para o seu Email</h1>
                    </div>

                    <div className="campo-senha">
                        <div className="row">
                            <div className="col-xl-2">
                                <input type="text" />
                            </div>

                            <div className="col-xl-2">

                            </div>

                            <div className="col-xl-2">

                            </div>

                            <div className="col-xl-1">

                            </div>

                            <div className="col-xl-2">

                            </div>

                            <div className="col-xl-2">

                            </div>

                            <div className="col-xl-2">

                            </div>

                            <div className="col-xl-2">

                            </div>
                        </div>
                    </div>

                    <div className="botoes">
                        <div className="row">
                            <div className="reenviar col-xl-6">
                                <button>Reenviar Codigo </button>
                            </div>

                            <div className="confirmar col-xl-6">
                                <Link to={"/ver-senha-cartao"}>
                                    <button>Confirmar</button>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}