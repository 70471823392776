import api from "./api";
import { toast } from "react-toastify";

export const CreateKey = async (keyType, keyValue, transationalPassword, navigate) => {
  const bank = localStorage.getItem("bank");
  const bankBranch = localStorage.getItem("bankBranch");
  const bankAccount = localStorage.getItem("bankAccount");
  const bankAccountDigit = localStorage.getItem("bankAccountDigit");
  const taxNumber = localStorage.getItem("taxNumber");

  let response = await api.post("/pixkey/store", {
    bank,
    bankBranch,
    bankAccount,
    bankAccountDigit,
    taxNumber,
    pixKey: keyValue,
    pixKeyType: keyType,
    transational_password: transationalPassword,
    token: localStorage.getItem('fiduciaToken'),
  });
  if (response.status === 200) {
    toast.success("Chave Criada com Sucesso!");
    setTimeout(() => {
      navigate("/chave-pix");
    }, 2000);
  } else {
    toast.error("Erro ao criar uma nova chave!");
  }
};

export const getAll = async () => {
  try{

    let response = await api.post("/pixkey/getKeys/", {
      personId: localStorage.getItem("personId"),
      taxNumber: localStorage.getItem("taxNumber"),
      bank: localStorage.getItem("bank"),
      bankBranch: localStorage.getItem("bankBranch"),
      bankAccount: localStorage.getItem("bankAccount"),
      bankAccountDigit: localStorage.getItem("bankAccountDigit"),
      token: localStorage.getItem('fiduciaToken'),
    });
    /* let response = await api.post('/pixkey/list/', {
      personId : localStorage.getItem('personId')
    }) */
    if (response.status === 200) {
      console.log("response.data.data ", response.data.data);
      return response.data.data;
    } else {
      toast.error("Erro ao carregar as chaves!");
      return ""
    }
  } catch(err) {
    toast.error(err.response.data.message);
    console.log("err.response.data.message ", err.response.data.message);
    return "";
  }
};

export const deleteKey = async (id, navigate) => {
    let deleteResponse = await api.delete(`/pixkey/delete/${id}`);
    if (deleteResponse.status === 200) {
      toast.success("Chave Deletada com Sucesso!");
      setTimeout(() => {
        navigate("/chave-pix");
      }, 2000);
    } else {
      toast.error("Erro ao deletar a chave!");
    }
};

export const deleteKeyFiducia = async (id, navigate, transationalPassword, token) => {
  try{

    let deleteResponse = await api.post(`/pixkey/fiducia/delete`, {
      pixkeyId: id,
      token,
      transational_password : transationalPassword
    });
    if (deleteResponse.status === 200) {
      toast.success("Chave Deletada com Sucesso!");
      setTimeout(() => {
        navigate("/chave-pix");
    }, 2000);
  } else {
    toast.error("Erro ao deletar a chave!");
  }
}catch(err){
  toast.error("Erro ao deletar a chave! " + err.response.data.message);
  console.log('err', err)
}

}

export const getPixInfo = async (key, type, taxNumber) => {
  let payload = {
    key,
    fromTaxNumber: taxNumber,
    keyType: type,
  };
  let infos = await api.post("/pix/getinfos", payload);
  return infos;
};

export const getPixDetailsFiducia = async (key) => {
  const token = localStorage.getItem("fiduciaToken");
  let payload = {
    key,
    token,
  };
  try{

    let response = await api.post("/pix/fiducia/getinfos", payload);
    
    let infos = {
      PixKeyType: response.data.data.tpChave,
      PixKeyValue: response.data.data.chave,
      ReceiverAccountType: response.data.data.tpConta,
      ReceiverBank: response.data.data.banco.numero_codigo,
      ReceiverBankAccount: response.data.data.nrConta.slice(0, -1),
      ReceiverBankAccountDigit: response.data.data.nrConta.slice(-1),
      ReceiverBankBranch: response.data.data.nrAgencia,
      ReceiverBankName: response.data.data.banco.nome_reduzido,
      ReceiverISPB: response.data.data.ispb,
      ReceiverName: response.data.data.nome,
      ReceiverTaxNumber: response.data.data.cpfCnpj,
    };
    return infos;
  } catch(err) {
    return err
  }
};

export const sendPixFitBank = async (data) => {
  let response = await api.post("/pix/transfer", data).catch((err) => {
    toast.error(err.response.data.message);
    return;
  });

  return response.data;
};

export const sendPixFiducia = async (data) => {

  let payload = {
    ...data,
    fromTaxNumber : localStorage.getItem('taxNumber'),
    token : localStorage.getItem('fiduciaToken')
  }
  let response = await api.post("/pix/fiducia/transfer", payload)

  return response.data;
};
